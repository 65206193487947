@import "../../../App/var";
@import "../../../App/App.scss";

.gallery {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-height: calc(100vh - 120px);
  position: relative;

  .gallery__title-info-box {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    // test
    position: absolute;
    top: -35px;
    left: 0px;

    h3 {
      font-size: $fz-xl;
      font-weight: 500;

      @media (max-width: $max-width-mobile) {
        font-size: $fz-m;
        text-transform: uppercase;
      }
    }
    p {
      font-size: $fz-m;

      .gallery__text-span {
        font-size: $fz-m;
        font-weight: 700;
      }
    }
    @media (max-width: $max-width-tablet) {
      position: static; 
      top: auto;
      left: auto;
    }
  }
  .gallery__not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    // test
    height: 100%;

    .gallery__not-found-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .gallery__not-found-image-box {
        display: flex;
        justify-content: center;
        max-width: 200px;
        height: auto;
        margin-bottom: 50px;

        img {
          object-fit: contain;     
          position: relative;
          z-index: 1;
          mix-blend-mode: multiply;
          opacity: 0.6;
          margin-bottom: 0px;
        }
        @media (max-width: $max-width-tablet) {
          max-height: 30vh;
        }
        @media (max-width: $max-width-mobile) {
          max-height: 28vh;
          max-width: 170px;
        }
      }
      .gallery__not-found-title-box  {
        margin-bottom: 20px;

        h2 {
          @include mv-weekend;
          font-size: $fz-xxxl2;
          color: $color-light-blue;
          text-align: center;
    
          @media (max-width: $max-width-mobile) {
            font-size: $fz-xxl2;
          }
        }
      }
    }
  }
  .gallery__persons {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;

    .gallery__person-box {
      display: flex;

      .gallery__btn-person-box {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-inside-box {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;

          .gallery__person-avatar-box {
            width: 83px;
            height: 83px;
            border-radius: 100%;
            border-style: solid;
            border-width: 3px;
            border-color: $color-light-blue;
            box-shadow: $box-shadow-normal;
            overflow: hidden;
            
  
            img {
              width: 100%;
              height: 100%;
              // height: auto;
              object-fit: cover; /* Сохраняет пропорции и заполняет контейнер */
              object-position: center; /* Центрирует изображение */
            }
            @media (max-width: $max-width-mobile) {
              width: 53px;
              height: 53px;
            }
          }
          .gallery__person-name-box {
  
            p {
              font-size: $fz-s;

              @media (max-width: $max-width-mobile) {
                font-size: $fz-xxs;
              }
            }
          }
        }
        .gallery__person-rename-box {
          display: flex;
          justify-content: center;
          align-items: center;

          .gallery__person-rename-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 26px;
            opacity: 0.5;

            img {
              width: 6px;
              height: auto;
            }
          }
          .gallery__person-rename-btn:hover {
            opacity: 1;
          }
        }
        .new-person {
          opacity: 0.5;
        }
        .new-person:hover {
          opacity: 0.9;
        }
      }
    }
    @media (max-width: $max-width-mobile) {
      margin-top: 10px;
      gap: 25px;
    }
    .gallery__person-box:hover {
      cursor: pointer;
    }
  }
  .gallery__btns-sections {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    .gallery__all-photos,
    .gallery__favourites,
    .gallery__paid {
      position: relative;

      .gallery__section-btn {

        h3 {
          font-size: $fz-m;
          font-weight: 500;
          text-transform: uppercase;

          @media (max-width: $max-width-mobile) {
            font-size: $fz-xs;
          }

        }
      }
      .gallery__stripe-black {
        position: absolute;
        bottom: -17px;
        left: 0;
        border-top: 3px solid $color-dark-blue-gray;
        width: 100%;
        line-height: 2px;
        margin: 7px 0;
      }
    }
    @media (max-width: $max-width-mobile) {
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  .gallery__stripe {
    border-top: 3px dashed rgba(142, 165, 174, 1);
    width: 100%;
    line-height: 3px;
    margin: 7px 0;
  }
  .gallery__section-all-photos {
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 30px;
    max-height: 55vh;
  }
  @media (max-width: $max-width-tablet) {
    max-height: calc(100vh - 320px);
    overflow: hidden;
  }
  @media (max-width: $max-width-mobile) {
    max-height: 100%;
    padding-bottom: 0px;
  }
}