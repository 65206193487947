@import "../../App/var.scss";

.burger-menu {
  // background-color: #bdf192;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 46vh;

  .burger-menu__logo-box {
    width: 95px;
    height: auto;
    // background-color: #fff;
    margin-bottom: $margit-bottom-xs;

    @media (max-width: $max-width-mobile) {
      width: 58px;
    }
  }
  .burger-menu__menu-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    // background-color: #f3c7c7;
    margin-bottom: $margit-bottom-xs;

    .burger-menu__menu-item {

      p {
        @media (max-width: $max-width-mobile) {
          font-size: $fz-xs;
        }
      }
    }
  }
  .burger-menu__log-in-box,
  .burger-menu__log-out-box {
    // background-color: #88abf5;
    margin-bottom: $margit-bottom-xs;

    .btn-burger-menu__log-in,
    .btn-burger-menu__log-out {
      display: flex;
      align-items: center;
      gap: 15px;

      .burger-menu__log-in-image-box,
      .burger-menu__log-out-image-box {
        width: 30px;
        height: auto;

        img {
          width: 100%;
        }

        @media (max-width: $max-width-mobile) {
          width: 20px;
          height: auto;
        }
      }
      .burger-menu__log-in-text-box,
      .burger-menu__log-out-text-box {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: $fz-l;
          font-weight: 500;
          text-transform: uppercase;

          @media (max-width: $max-width-mobile) {
            font-size: $fz-m;
          }
        }
      }
      @media (max-width: $max-width-mobile) {
        gap: 10px;
      }
    }
  }
  .burger-menu__contacts {
    display: flex;
    flex-direction: column;
    // background-color: #f390e6;
    gap: 1vh;
    margin-bottom: $margit-bottom-xs;

    p {
      font-size: $fz-s;
      font-weight: 500;
      text-align: center;

      @media (max-width: $max-width-mobile) {
        font-size: $fz-xxs;
        font-weight: 700;
      }
    }
    .burger-menu__contacts-item-box {

      .burger-menu__contacts-item {
        display: flex;
        gap: 15px;

        .burger-menu__contacts-icon-box {
          width: 24px;
          height: auto;

          img {
            width: 100%;
          }
          @media (max-width: $max-width-mobile) {
            width: 15px;
          }
        }

        p {
          font-size: $fz-s;
          font-weight: 400;

          @media (max-width: $max-width-mobile) {
            font-size: $fz-xs;
          }
        }
      }
    }
  }
  .burger-menu__socials {
    display: flex;
    flex-direction: column;
    // background-color: #35923d;
    gap: 1vh;

    p {
      font-size: $fz-s;
      font-weight: 500;
      text-align: center;

      @media (max-width: $max-width-mobile) {
        font-size: $fz-xxs;
        font-weight: 700;
      }
    }

    .burger-menu__socials-box {
      display: flex;
      gap: 25px;

      .burger-menu__socials-item {
        height: 41px;
        width: auto;
  
        img {
          height: 100%;
        }
        @media (max-width: $max-width-mobile) {
          height: 30px;
        }
      }
      @media (max-width: $max-width-mobile) {
        gap: 20px;
      }
    }
  }
}