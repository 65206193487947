@import '../../App/var';


.leftbar {
  // old version
  // min-height: 640px;
  // height: 100%;
  // min-width: 330px;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // z-index: 50;
  // padding: 90px 35px 90px 22px;
  // background: 
  //         url("../../../assets/images/desktop-menu-small-center-200.png") no-repeat right center / 130px 200px,
  //         url("../../../assets/images/desktop-menu-small-top-200.png") no-repeat right top / 130px 200px,
  //         url("../../../assets/images/desktop-menu-small-main-1080.png") no-repeat calc(100% - 29px) center / 101px 100%,
  //         linear-gradient(to right, #d7f4f8, #d7f4f8 calc(100% - 50px), transparent calc(100% - 50px)),
  //         ;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // gap: 15px;

  // new version
  min-height: 640px;
  height: 100%;
  width: 330px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  padding: 90px 35px 90px 22px;
  background: 
          url("../../../assets/images/desktop-menu-small-center-200.png") no-repeat right center / 130px 200px,
          url("../../../assets/images/desktop-menu-small-top-200.png") no-repeat right top / 130px 200px,
          url("../../../assets/images/desktop-menu-small-main-1080.png") no-repeat calc(100% - 29px) center / 101px 100%,
          linear-gradient(to right, #d7f4f8, #d7f4f8 calc(100% - 50px), transparent calc(100% - 50px)),
          ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  transition-property: width;
  transition-duration: 1s;
  transition-timing-function: ease;

  .menu__logo-box {
      position: absolute;
      top: 40px;
      //  Первый вариант
      // left: auto;
      left: 50%;
      transform: translateX(-85%);
      z-index: 51;
      height: 47px;
      width: auto;
  
      img {
        height: 100%;
      }
  }
  .menu__arrow-box {
    position: absolute;
    top: 36px;
    right: 4px;
    z-index: 51;
    padding: 25px 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transform: rotate(0deg);
      width: 7px;
      height: 10px;
    }
    .arrow-to-left {
      transform: rotate(180deg);
    }

  }
  .menu__hint {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: -180px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 52;
        mix-blend-mode: multiply;


    .menu__hint__image-box {
      display: flex;
      width: 26px;
      height: auto;
      
      img {
        position: relative;
        z-index: 53;
        mix-blend-mode: multiply;
        opacity: 0.95;
        width: 100%;
      }
    }
    .menu__hint__text-box {
      display: flex;
      justify-content: start;
      width: 137px;

      p {
        font-size: 16px;
      }
    }
  }
  .menu__top, .menu__down {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 5px;
      flex-grow: 1;
      width: 100%;
  
      .menu__top__item, .menu__down__item {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 6px;
  
        .menu__item__image-box {
          width: 45px;
          min-width: 45px;
          // old
          // height: auto;
          // new
          height: 34px;
          min-height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 100%;
            width: auto;
          }
        }
        .menu__item__text-box {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          overflow: hidden;

          // old
          // p {
          //   font-size: $fz-m;
          // }
          // new
          .menu__item__text {
            font-size: $fz-m;
            opacity: 1;
            transition: opacity 1s ease-in, visibility 1s ease-in, font-size 1s ease-in;
            // transition-delay: 0.5s;
          }
          .menu__item__text.hide-text {
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease-in, visibility 1s ease-in;
            // transition-delay: 1s;
            font-size: 0px;
          }
        }
      }
  }
  .menu__center {
    display: flex;
    width: 100%;

      .menu__center__item {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        gap: 6px;
  
        .menu__center-item__image-box {
          width: 63px;
          height: auto;
          // height: 100px;
          // width: auto;
          position: relative;
          display: flex;
          justify-content: center;
          background: none;
  
          img {
            height: auto;
            // width: 100%;
            position: relative;
            z-index: 1;
            mix-blend-mode: multiply;
            opacity: 0.8;
          }
        }
        .menu__item__text-box {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          // old
          // p {
          //   font-size: $fz-m;
          // }
          // new
          .menu__item__text {
            font-size: $fz-m;
            opacity: 1;
            transition: opacity 1s ease-in, visibility 1s ease-in, font-size 1s ease-in;
            // transition-delay: 0.5s;
          }
          .menu__item__text.hide-text {
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease-in, visibility 1s ease-in;
            // transition-delay: 1s;
            font-size: 0px;
          }
        }
      }
  }
  
  @media (max-width: $max-width-tablet) {
    display: none;
  }
}
// old version
// .leftbar-collapse {
//   min-width: 130px;
// }

// new version
.leftbar-collapse {
  width: 130px;
}
