// Шрифты
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI-VF";
  src: url("/src/fonts/Golos-UI-VF-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Medium-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "MV-WEEKEND";
  src: url("/src/fonts/MV-WEEKEND.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}