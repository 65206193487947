@import '../../../App/var';
@import '../../../App/App.scss';

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .about__title-box {

    h2 {
      @include mv-weekend;
      font-size: 100px;
      line-height: 80px;
      color: $color-light-blue;
      text-align: center;
      margin-bottom: 50px;

      @media (max-width: $max-width-tablet) {
        margin-bottom: 20px;
      }
      @media (max-width: $max-width-mobile) {
        font-size: 45px;
        line-height: 36px;
        margin-bottom: 20px;
      }
    }
  }
  .about__image-box {
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;

    img {
      max-height: 370px;
      height: 100%;
      width: auto;
      position: relative;
      z-index: 1;
      mix-blend-mode: multiply;
      opacity: 0.8;
      margin-bottom: 0px;
    }
    @media (max-width: $max-width-tablet) {
      max-height: 30vh;
    }
    @media (max-width: $max-width-mobile) {
      max-height: 28vh;
    }
  }
  .about__text-box {
    max-width: 662px;
    margin-bottom: 3vh;
    p {
      font-size: $fz-l;
      text-align: center;

      @media (max-width: $max-width-mobile) {
        font-size: 16px;
      }
    }
    @media (max-width: $max-width-tablet) {
      margin-bottom: 1vh;
    }
    @media (max-width: $max-width-mobile) {
      margin-bottom: 3vh;
    }
  }
}