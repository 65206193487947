@import "../../App/var";
@import "../../App/App.scss";

.hr-gray {
  width: 100%;
  padding: 0;
  margin: 0;
  border-style: none;
  height: 1px;
  background-color: rgb(69, 86, 92);;
}

.wrong-data-box {
  color: red;
}

.confirm-email-reg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .confirm-email-reg__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 50px;

    .title-description {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-family: "MV-WEEKEND", sans-serif;
        font-size: 64px;
        line-height: 64px;
        color: rgb(238, 251, 255);
        text-align: center;
        margin-bottom: 10px;

        @media (max-width: $max-width-mobile) {
          font-family: "Golos-UI", sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          color: rgb(69, 86, 92);
          text-transform: uppercase;
        }
      }
      p {
        text-align: center;
        margin-bottom: 10px;
      }
      @media (max-width: $max-width-mobile) {
        margin-top: 50px;
      }
      @media (max-width: $max-width-tablet) {
        margin-top: 50px;
      }
    }
    .confirm-email-reg__form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 600px;
      width: 100%;

      .info-box {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .image-text {
          display: flex;
          align-items: center;
          gap: 10px;

          .image-box {
            height: 31px;
            width: auto;
            min-width: 31px;

            img {
              height: 100%;
              width: auto;
              opacity: 0.5;
            }
          }
        }
      }
      .input-code-box {
        display: flex;
        flex-direction: column;

        .input-field {
          // flex-direction: row;
          // column-gap: 10px;
          display: flex;
          justify-content: start;
          gap: 20px;
          margin-top: 5px;

          input {
            background-color: transparent;
            width: 50px;
            height: 70px;
            @include golos;
            font-size: $fz-xxxl2;
            line-height: 1.1;
            color: $color-dark-blue-gray;
            text-align: center;
            padding: 5px;
            border-top: none;
            border-right: none;
            border-bottom: 2px solid $color-dark-blue-gray;
            border-left: none;
          }
          input:focus {
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
          }
          input::-webkit-inner-spin-button,
          input::-webkit-outer-spin-button {
            display: none;
          }
          .modal-code-input-wrong {
            color: $color-warning;
          }
          .modal-code-input:focus {
            border-bottom: 2px solid $color-dark-blue-gray;
            outline: none;
          }
        }
        .refresh-box {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
          cursor: pointer;

          .image-box {
            height: 26px;
            width: auto;
            min-width: 26px;

            img {
              height: 100%;
              width: auto;
              transition: transform 1s ease-in-out;
            }
            img:hover {
              transform: rotate(90deg);
          }
          }
          .link {
            color: $color-cool-stone;
            text-decoration: underline;
          }
          .link:hover {
            cursor: pointer;
            color: $color-misty-sea;
          }
        }
        .refresh-box:hover {
          
          img {
            transform: rotate(90deg);
        }
        }
      }
      .what-do-box {
        display: flex;
        flex-direction: column;
      }
      .image-text {
        display: flex;
        align-items: center;
        gap: 10px;

        .image-box {
          height: 31px;
          width: auto;
          min-width: 31px;

          img {
            height: 100%;
            width: auto;
          }
        }
      }
      .bts-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
}
