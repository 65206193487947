@import "../../../../App/var";
@import "../../../../App/App.scss";

.payment {
  width: 29%;
  min-width: 385px;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;

  // Блок - количество фото и общая стоимость
  .payment__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;

    p {
      font-size: $fz-l;
      font-weight: 500;

      @media (max-width: $max-width-mobile) {
        font-size: $fz-m;
      }
    }
    @media (max-width: $max-width-mobile) {
      margin-bottom: 10px;
    }
  }
  // Блок - выбор метода оплаты
  .payment__method {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;

    p {
      font-size: $fz-l;
      font-weight: 500;

      @media (max-width: $max-width-mobile) {
        font-size: $fz-m;
      }
    }

    // Блок - один вариант оплаты
    .payment__method-item {
      display: flex;
      align-items: center;
      gap: 10px;

      // Кастомный чек-бокс (радио-кнопка)
      .image__checkbox-select-box {
        position: relative;
        width: 24px;
        height: 24px;

        .image__checkbox-select-method {
          position: absolute;
          z-index: 2;
          top: 0px;
          right: 0px;
          width: 24px;
          height: 24px;
          border-radius: 10px;
        }
        .custom-checkbox {
          display: flex;
          align-items: center;
          gap: 5px;
  
          // Скрываем стандартный чек-бокс
          .custom-checkbox-input {
            display: none;
          }
          // Показываем картинку - кастомного чек-бокса
          .custom-checkbox-icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('../../../../../assets/images/image-checkbox-uncheck.png');
            background-size: cover;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 25px;
          }
          /* Меняем изображение при активации чекбокса */
          .custom-checkbox-input:checked + .custom-checkbox-icon {
            background-image: url('../../../../../assets/images/image-checkbox-checked.png');
          }
        }
      }
      // Название способа оплаты
      .payment__method-item-name-box {
        p {
          font-size: $fz-m;
          font-weight: 400;

          @media (max-width: $max-width-mobile) {
            font-size: $fz-xs;
          }
        }
      }
      // Картинка
      .payment__method-item-image-box {
        height: 20px;

        img {
          height: 100%;
          width: auto;
        }
      }
    }
    .payment__method-item.disabled {

      .payment__method-item-name-box {

        p {
          color: $color-dark-blue-gray05;
        }
      }
    }
    @media (max-width: $max-width-tablet) {
      margin-bottom: 0;
    }
  }
  // Блок - Реквизиты в зависимости от способа оплаты
  .payment__requisites {

    // Реквизиты - при способе картой
    .payment__cards {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .payment__cards-title {
        margin-bottom: 20px;

        p {
          font-size: $fz-l;
          font-weight: 500;

          @media (max-width: $max-width-mobile) {
            font-size: $fz-m;
          }
        }
        @media (max-width: $max-width-mobile) {
          margin-bottom: 0;
        }
      }
      // Выбранная карта
      .payment__cards-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 330px;
        padding: 10px;
        border-radius: 10px;
        border-style: none;
        background-color: $color-light-blue07;

        .payment__cards-item-left {
          display: flex;
          gap: 15px;

          .payment__cards-item-logo-box {
            width: 50px;

            img {
              width: 100%;
              height: auto;
            }
          }
          .payment__cards-item-name-bank {
            display: flex;
            align-items: center;

            p {
              font-size: $fz-s;
              font-weight: 700;

              @media (max-width: $max-width-mobile) {
                font-size: $fz-xs;
              }
            }
          }
        }
        .payment__cards-item-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 10px;

          .payment__cards-item-card-number {

            p {
              font-size: $fz-xs;
            }
          }
          .image__checkbox-select-box {
            position: relative;
            width: 24px;
            height: 24px;
    
            .image__checkbox-select-method {
              position: absolute;
              z-index: 2;
              top: 0px;
              right: 0px;
              width: 24px;
              height: 24px;
              border-radius: 10px;
            }
            .custom-checkbox {
              display: flex;
              align-items: center;
              gap: 5px;
      
              // Скрываем стандартный чек-бокс
              .custom-checkbox-input {
                display: none;
              }
              // Показываем картинку - кастомного чек-бокса
              .custom-checkbox-icon {
                display: inline-block;
                width: 24px;
                height: 24px;
                background-image: url('../../../../../assets/images/image-checkbox-uncheck.png');
                background-size: cover;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 25px;
              }
              /* Меняем изображение при активации чекбокса */
              .custom-checkbox-input:checked + .custom-checkbox-icon {
                background-image: url('../../../../../assets/images/image-checkbox-checked.png');
              }
            }
          }
        }
        @media (max-width: $max-width-mobile) {
          max-width: 320px;
        }
      }
      @media (max-width: $max-width-mobile) {
        margin-top: 20px;
      }
    }
  }
  @media (max-width: $max-width-mobile) {
    width: 100%;
    min-width: 300px;
  }
}