.header {
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 80px;
  padding: 25px 30px 10px 30px;
}
.header .header__left {
  display: none;
}
.header .header__left .header__btn-menu {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border-style: none;
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
}
.header .header__left .header__btn-menu img {
  width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .header .header__left .header__btn-menu {
    width: 36px;
    height: 36px;
  }
}
@media (max-width: 1199px) {
  .header .header__left {
    display: flex;
    min-width: 160px;
    gap: 30px;
  }
}
@media (max-width: 767px) {
  .header .header__left {
    display: flex;
    min-width: 100px;
    gap: 20px;
  }
}
.header .header__center {
  display: none;
  max-width: 125px;
  height: auto;
}
.header .header__center img {
  width: 100%;
}
@media (max-width: 1199px) {
  .header .header__center {
    display: flex;
  }
}
@media (max-width: 767px) {
  .header .header__center {
    max-width: 58px;
  }
}
.header .header__right {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}
.header .header__right .header__btn-login-box,
.header .header__right .header__btn-basket-box,
.header .header__right .header__btn-logout-box {
  display: none;
  width: 64px;
  height: 64px;
}
.header .header__right .header__btn-login-box .header__btn-login,
.header .header__right .header__btn-login-box .header__btn-basket,
.header .header__right .header__btn-login-box .header__btn-logout,
.header .header__right .header__btn-basket-box .header__btn-login,
.header .header__right .header__btn-basket-box .header__btn-basket,
.header .header__right .header__btn-basket-box .header__btn-logout,
.header .header__right .header__btn-logout-box .header__btn-login,
.header .header__right .header__btn-logout-box .header__btn-basket,
.header .header__right .header__btn-logout-box .header__btn-logout {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-style: none;
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  position: relative;
}
.header .header__right .header__btn-login-box .header__btn-login img,
.header .header__right .header__btn-login-box .header__btn-basket img,
.header .header__right .header__btn-login-box .header__btn-logout img,
.header .header__right .header__btn-basket-box .header__btn-login img,
.header .header__right .header__btn-basket-box .header__btn-basket img,
.header .header__right .header__btn-basket-box .header__btn-logout img,
.header .header__right .header__btn-logout-box .header__btn-login img,
.header .header__right .header__btn-logout-box .header__btn-basket img,
.header .header__right .header__btn-logout-box .header__btn-logout img {
  width: 100%;
  height: auto;
}
.header .header__right .header__btn-login-box .header__btn-login .cart-badge,
.header .header__right .header__btn-login-box .header__btn-basket .cart-badge,
.header .header__right .header__btn-login-box .header__btn-logout .cart-badge,
.header .header__right .header__btn-basket-box .header__btn-login .cart-badge,
.header .header__right .header__btn-basket-box .header__btn-basket .cart-badge,
.header .header__right .header__btn-basket-box .header__btn-logout .cart-badge,
.header .header__right .header__btn-logout-box .header__btn-login .cart-badge,
.header .header__right .header__btn-logout-box .header__btn-basket .cart-badge,
.header .header__right .header__btn-logout-box .header__btn-logout .cart-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(252, 131, 131);
  padding: 1px;
  border-style: none;
  border-radius: 100%;
  min-width: 25px;
  min-height: 25px;
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 0;
}
.header .header__right .header__btn-login-box .header__btn-login .cart-badge p,
.header .header__right .header__btn-login-box .header__btn-basket .cart-badge p,
.header .header__right .header__btn-login-box .header__btn-logout .cart-badge p,
.header .header__right .header__btn-basket-box .header__btn-login .cart-badge p,
.header .header__right .header__btn-basket-box .header__btn-basket .cart-badge p,
.header .header__right .header__btn-basket-box .header__btn-logout .cart-badge p,
.header .header__right .header__btn-logout-box .header__btn-login .cart-badge p,
.header .header__right .header__btn-logout-box .header__btn-basket .cart-badge p,
.header .header__right .header__btn-logout-box .header__btn-logout .cart-badge p {
  font-size: 14px;
  line-height: 1;
}
@media (max-width: 767px) {
  .header .header__right .header__btn-login-box .header__btn-login .cart-badge p,
  .header .header__right .header__btn-login-box .header__btn-basket .cart-badge p,
  .header .header__right .header__btn-login-box .header__btn-logout .cart-badge p,
  .header .header__right .header__btn-basket-box .header__btn-login .cart-badge p,
  .header .header__right .header__btn-basket-box .header__btn-basket .cart-badge p,
  .header .header__right .header__btn-basket-box .header__btn-logout .cart-badge p,
  .header .header__right .header__btn-logout-box .header__btn-login .cart-badge p,
  .header .header__right .header__btn-logout-box .header__btn-basket .cart-badge p,
  .header .header__right .header__btn-logout-box .header__btn-logout .cart-badge p {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .header .header__right .header__btn-login-box .header__btn-login .cart-badge,
  .header .header__right .header__btn-login-box .header__btn-basket .cart-badge,
  .header .header__right .header__btn-login-box .header__btn-logout .cart-badge,
  .header .header__right .header__btn-basket-box .header__btn-login .cart-badge,
  .header .header__right .header__btn-basket-box .header__btn-basket .cart-badge,
  .header .header__right .header__btn-basket-box .header__btn-logout .cart-badge,
  .header .header__right .header__btn-logout-box .header__btn-login .cart-badge,
  .header .header__right .header__btn-logout-box .header__btn-basket .cart-badge,
  .header .header__right .header__btn-logout-box .header__btn-logout .cart-badge {
    min-width: 23px;
    min-height: 23px;
    top: -5px;
    right: -5px;
  }
}
@media (max-width: 1199px) {
  .header .header__right .header__btn-login-box,
  .header .header__right .header__btn-basket-box,
  .header .header__right .header__btn-logout-box {
    display: flex;
  }
}
@media (max-width: 767px) {
  .header .header__right .header__btn-login-box,
  .header .header__right .header__btn-basket-box,
  .header .header__right .header__btn-logout-box {
    display: flex;
    width: 36px;
    height: 36px;
  }
}
.header .header__right .header__btn-login-desktop,
.header .header__right .header__btn-basket-desktop,
.header .header__right .header__btn-logout-desktop {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
.header .header__right .header__btn-login-desktop .header__btn-login-desktop__img-box,
.header .header__right .header__btn-login-desktop .header__btn-basket-desktop__img-box,
.header .header__right .header__btn-login-desktop .header__btn-logout-desktop__img-box,
.header .header__right .header__btn-basket-desktop .header__btn-login-desktop__img-box,
.header .header__right .header__btn-basket-desktop .header__btn-basket-desktop__img-box,
.header .header__right .header__btn-basket-desktop .header__btn-logout-desktop__img-box,
.header .header__right .header__btn-logout-desktop .header__btn-login-desktop__img-box,
.header .header__right .header__btn-logout-desktop .header__btn-basket-desktop__img-box,
.header .header__right .header__btn-logout-desktop .header__btn-logout-desktop__img-box {
  height: 29px;
  width: auto;
  position: relative;
}
.header .header__right .header__btn-login-desktop .header__btn-login-desktop__img-box .img,
.header .header__right .header__btn-login-desktop .header__btn-basket-desktop__img-box .img,
.header .header__right .header__btn-login-desktop .header__btn-logout-desktop__img-box .img,
.header .header__right .header__btn-basket-desktop .header__btn-login-desktop__img-box .img,
.header .header__right .header__btn-basket-desktop .header__btn-basket-desktop__img-box .img,
.header .header__right .header__btn-basket-desktop .header__btn-logout-desktop__img-box .img,
.header .header__right .header__btn-logout-desktop .header__btn-login-desktop__img-box .img,
.header .header__right .header__btn-logout-desktop .header__btn-basket-desktop__img-box .img,
.header .header__right .header__btn-logout-desktop .header__btn-logout-desktop__img-box .img {
  height: 100%;
}
.header .header__right .header__btn-login-desktop .header__btn-login-desktop__img-box .cart-badge,
.header .header__right .header__btn-login-desktop .header__btn-basket-desktop__img-box .cart-badge,
.header .header__right .header__btn-login-desktop .header__btn-logout-desktop__img-box .cart-badge,
.header .header__right .header__btn-basket-desktop .header__btn-login-desktop__img-box .cart-badge,
.header .header__right .header__btn-basket-desktop .header__btn-basket-desktop__img-box .cart-badge,
.header .header__right .header__btn-basket-desktop .header__btn-logout-desktop__img-box .cart-badge,
.header .header__right .header__btn-logout-desktop .header__btn-login-desktop__img-box .cart-badge,
.header .header__right .header__btn-logout-desktop .header__btn-basket-desktop__img-box .cart-badge,
.header .header__right .header__btn-logout-desktop .header__btn-logout-desktop__img-box .cart-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(252, 131, 131);
  padding: 1px;
  border-style: none;
  border-radius: 100%;
  min-width: 25px;
  min-height: 25px;
  position: absolute;
  z-index: 10;
  top: -10px;
  right: -10px;
}
.header .header__right .header__btn-login-desktop .header__btn-login-desktop__img-box .cart-badge p,
.header .header__right .header__btn-login-desktop .header__btn-basket-desktop__img-box .cart-badge p,
.header .header__right .header__btn-login-desktop .header__btn-logout-desktop__img-box .cart-badge p,
.header .header__right .header__btn-basket-desktop .header__btn-login-desktop__img-box .cart-badge p,
.header .header__right .header__btn-basket-desktop .header__btn-basket-desktop__img-box .cart-badge p,
.header .header__right .header__btn-basket-desktop .header__btn-logout-desktop__img-box .cart-badge p,
.header .header__right .header__btn-logout-desktop .header__btn-login-desktop__img-box .cart-badge p,
.header .header__right .header__btn-logout-desktop .header__btn-basket-desktop__img-box .cart-badge p,
.header .header__right .header__btn-logout-desktop .header__btn-logout-desktop__img-box .cart-badge p {
  font-size: 12px;
  line-height: 1;
}
.header .header__right .header__btn-login-desktop .header__btn-login-desktop__text-box,
.header .header__right .header__btn-login-desktop .header__btn-basket-desktop__text-box,
.header .header__right .header__btn-login-desktop .header__btn-logout-desktop__text-box,
.header .header__right .header__btn-basket-desktop .header__btn-login-desktop__text-box,
.header .header__right .header__btn-basket-desktop .header__btn-basket-desktop__text-box,
.header .header__right .header__btn-basket-desktop .header__btn-logout-desktop__text-box,
.header .header__right .header__btn-logout-desktop .header__btn-login-desktop__text-box,
.header .header__right .header__btn-logout-desktop .header__btn-basket-desktop__text-box,
.header .header__right .header__btn-logout-desktop .header__btn-logout-desktop__text-box {
  display: flex;
  align-items: center;
}
.header .header__right .header__btn-login-desktop .header__btn-login-desktop__text-box p,
.header .header__right .header__btn-login-desktop .header__btn-basket-desktop__text-box p,
.header .header__right .header__btn-login-desktop .header__btn-logout-desktop__text-box p,
.header .header__right .header__btn-basket-desktop .header__btn-login-desktop__text-box p,
.header .header__right .header__btn-basket-desktop .header__btn-basket-desktop__text-box p,
.header .header__right .header__btn-basket-desktop .header__btn-logout-desktop__text-box p,
.header .header__right .header__btn-logout-desktop .header__btn-login-desktop__text-box p,
.header .header__right .header__btn-logout-desktop .header__btn-basket-desktop__text-box p,
.header .header__right .header__btn-logout-desktop .header__btn-logout-desktop__text-box p {
  text-transform: uppercase;
}
@media (max-width: 1199px) {
  .header .header__right .header__btn-login-desktop,
  .header .header__right .header__btn-basket-desktop,
  .header .header__right .header__btn-logout-desktop {
    display: none;
  }
}
@media (max-width: 1199px) {
  .header .header__right {
    min-width: 160px;
    gap: 30px;
  }
}
@media (max-width: 767px) {
  .header .header__right {
    min-width: 100px;
    gap: 20px;
  }
}
.header .header__btn-back {
  width: 64px;
  height: 64px;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -56px;
  left: 30px;
  cursor: pointer;
}
.header .header__btn-back img {
  width: 40px;
  height: auto;
}
@media (max-width: 767px) {
  .header .header__btn-back img {
    width: 21px;
  }
}
@media (max-width: 1199px) {
  .header .header__btn-back {
    display: flex;
    bottom: -76px;
  }
}
@media (max-width: 767px) {
  .header .header__btn-back {
    width: 36px;
    height: 36px;
    bottom: -36px;
    left: 15px;
  }
}
@media (max-width: 1199px) {
  .header {
    justify-content: space-between;
    position: fixed;
    z-index: 50;
    top: 0px;
    left: 0px;
    width: 100%;
    background-size: 50px 50px, auto;
    background-repeat: round, no-repeat;
    background: url("../../assets/images/image-noize.png"), linear-gradient(90deg, rgb(151, 199, 204) 0%, rgb(192, 232, 237) 40%, rgb(192, 232, 237) 60%, rgb(151, 199, 204) 100%);
    box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
  }
}
@media (max-width: 767px) {
  .header {
    padding: 10px 15px 10px 15px;
    min-height: 70px;
  }
}/*# sourceMappingURL=HeaderClient.css.map */