@import "../../../App/var";
@import "../../../App/App.scss";

// Частоиспользуемые стили
// Горизонтальная линия
.line-box {
  display: flex;
  align-items: center;
  flex: 1; /* Занимает всё оставшееся пространство */
  height: 100%;
  width: 20px;

  .line-hr {
    height: 1px;
    width: 100%;
    background-color: $color-dark-blue-gray;
    margin: 0;
    padding: 0;
    border-style: none;
  }
}
// Вертикальная пунктирная линия
.account__line-box {
  display: flex;
  justify-content: center;
  min-width: 6px;
  width: 5%;

  .account__line-hr {
    height: 100%;
    border-right: 2px dashed $color-steel-blue;
  }
  @media (max-width: $max-width-tablet) {
    display: none;
  }
}
// Инпут переключатель (switch)
.switch-box {
  display: flex;
  align-items: center;
  flex: 0 0 auto; /* Занимает только необходимое пространство */
  height: 100%;
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
  }
  .switch input { 
      opacity: 0;
      width: 0;
      height: 0;
  }
  .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-pale-blue08;
      transition: .4s;
  }
  .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: $color-dark-blue-gray;
      transition: .4s;
  }
  input:checked + .slider {
      background-color: $color-light-blue;
  }
  input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
      transform: translateX(15px);
  }
  /* Ручная настройка внешнего вида */
  .slider.round {
      border-radius: 25px;
  }
  .slider.round:before {
      border-radius: 50%;
  }
}
// Кастомный Чек-бокс 20х20пикс
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  // Скрываем стандартный чек-бокс
  .custom-checkbox-input {
    display: none;
  }
  // Показываем картинку - кастомного чек-бокса
  .custom-checkbox-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('../../../../assets/images/image-checkbox-uncheck.png');
    background-size: cover;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 25px;
    @media (max-width: $max-width-mobile) {
      width: 15px;
      height: 15px;
    }
  }
  /* Меняем изображение при активации чекбокса */
  .custom-checkbox-input:checked + .custom-checkbox-icon {
    background-image: url('../../../../assets/images/image-checkbox-checked.png');
  }
  @media (max-width: $max-width-mobile) {
    width: 15px;
    height: 15px;
  }
}
// Банковская карта (подключенная для оплаты)
.payment__cards-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 330px;
  min-height: 70px;
  padding: 10px;
  border-radius: 10px;
  border-style: none;
  background-color: $color-light-blue07;

  .payment__cards-item-left {
    display: flex;
    gap: 15px;

    .payment__cards-item-logo-box {
      width: 50px;

      img {
        width: 100%;
        height: auto;
      }
    }
    .payment__cards-item-name-bank {
      display: flex;
      align-items: center;

      p {
        font-size: $fz-s;
        font-weight: 700;

        @media (max-width: $max-width-mobile) {
          font-size: $fz-xs;
        }
      }
    }
  }
  .payment__cards-item-right {
    display: flex;
    align-items: flex-start;
    height: 100%;
    // flex-direction: column;
    // align-items: flex-end;
    gap: 10px;

    .payment__cards-item-card-number {

      p {
        font-size: $fz-xs;
      }
    }
    .image__checkbox-select-box {
      position: relative;
      width: 24px;
      height: 24px;

      .image__checkbox-select-method {
        position: absolute;
        z-index: 2;
        top: 0px;
        right: 0px;
        width: 24px;
        height: 24px;
        border-radius: 10px;
      }
      .custom-checkbox {
        display: flex;
        align-items: center;
        gap: 5px;

        // Скрываем стандартный чек-бокс
        .custom-checkbox-input {
          display: none;
        }
        // Показываем картинку - кастомного чек-бокса
        .custom-checkbox-icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-image: url('../../../../assets/images/image-checkbox-uncheck.png');
          background-size: cover;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 25px;
        }
        /* Меняем изображение при активации чекбокса */
        .custom-checkbox-input:checked + .custom-checkbox-icon {
          background-image: url('../../../../assets/images/image-checkbox-checked.png');
        }
      }
    }
  }
}

.account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;

  .account__title-info-box {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    position: absolute;
    top: -35px;
    left: 0px;

    h3 {
      font-size: $fz-xl;
      font-weight: 500;

      @media (max-width: $max-width-mobile) {
        font-size: $fz-m;
        text-transform: uppercase;
      }
    }
    @media (max-width: $max-width-tablet) {
      position: static; 
      top: auto;
      left: auto;
      margin-left: 30px;
    }
    @media (max-width: $max-width-mobile) {
      margin-left: 0;
    }
  }
  .account__place {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 50px;

    .account__avatar-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 110px;
      margin-bottom: 30px;

      // Бокс 131 на 106 пикс
      .account__avatar-wrapper {
        width: 131px;
        height: 106px;
        position: relative;

        // Нижний слой - Меню - карандаш
        .account__avatar-mini-menu-box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 9px;
          position: absolute;
          z-index: 10;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-style: none;
          width: 40px;
          height: 30px;
          background-color: $color-light-blue;

          img {
            height: 20px;
            width: auto;
            opacity: 0.3;
          }
        }
        .account__avatar-mini-menu-box:hover {
          cursor: pointer;
        }
        .account__avatar-mini-menu-box:hover img {
          opacity: 0.7;
        }
        .account__avatar-mini-menu-box:active img {
          opacity: 1;
        }
        .account__avatar-photo-box {
          width: 106px;
          height: 106px;
          border-width: 3px;
          border-style: solid;
          border-radius: 100%;
          border-color: $color-light-blue;
          position: absolute;
          z-index: 11;
          top: 0px;
          left: 0px;
          background-color: #a09191;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 69px;
            width: auto;
          }
        }
      }
      @media (max-width: $max-width-tablet) {
        justify-content: center;
        margin-bottom: 20px;
      }
    }
    .account__place-center {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      // 1 - Данные пользователя (Имя, телефон и т.д.)
      .account__dates {
        width: 100%;
        min-width: 360px;
        // min-width: 320px;
        max-width: 25%;

        // Блок - Имя и фамилия
        .account__name-lastname {
          max-width: 360px;
          width: 100%;
          height: 115px;
          padding: 1px;
          display: flex;
          flex-direction: column;

          .modal-input-box {
            width: 100%;

            .input-wrapper {
              width: 100%;
              position: relative;
              
              .modal-input {
                margin-top: 5px;
                padding: 18px 20px;
                width: 100%;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;
                border: 1px solid transparent;
                background-color: $color-white;
                @include golos;
                font-weight: 400;
                font-size: $fz-s;
                line-height: 20px;
                color: $color-stormy-sea;
            
                @media (max-width: $max-width-mobile) {
                  font-size: $fz-xs;
                  line-height: 1.2;
                }
              }
              .input-pencil-box {
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 10;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                cursor: pointer;

                img {
                  height: 20px;
                  width: auto;
                }
              }
            }
          }
        }
        // Блок - Дата рождения и Пол
        .account__second-string {
          max-width: 360px;
          width: 100%;
          height: 115px;
          padding: 1px;
          display: flex;
          flex-direction: row;
          gap: 20px;

          .account__birthday-box {
            max-width: 180px;
            width: 100%;
            height: 115px;
            padding: 1px;
            display: flex;
            flex-direction: column;

            .modal-input-box {
              width: 100%;

              .input-wrapper {
                width: 100%;
                position: relative;

                .modal-input {
                  margin-top: 5px;
                  padding: 18px 20px;
                  width: 100%;
                  border-bottom-left-radius: 20px;
                  border-bottom-right-radius: 20px;
                  border-top-right-radius: 20px;
                  border: 1px solid transparent;
                  background-color: $color-white;
                  @include golos;
                  font-weight: 400;
                  font-size: $fz-s;
                  line-height: 20px;
                  color: $color-stormy-sea;
                  position: relative;
              
                  @media (max-width: $max-width-mobile) {
                    font-size: $fz-xs;
                    line-height: 1.2;
                  }
                }
                .input-pencil-box {
                  height: 30px;
                  width: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  z-index: 10;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 10px;
                  cursor: pointer;

                  img {
                    height: 20px;
                    width: auto;
                  }
                }
              }
            }
          }
          .account__gender-box {

            p {
              font-size: $fz-s;
            }
            .account__gender-wrapper {
              display: flex;
              gap: 10px;

              .account__gender-item {
                height: 60px;
                margin-top: 5px;

                .checkbox__wrapper {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  height: 100%;
                }
              }
            }
          }
          @media (max-width: $max-width-mobile) {
            max-width: 320px;
            gap: 10px;
          }
        }
        // Блок - Номер телефона
        .account__phone {
          max-width: 360px;
          width: 100%;
          height: 115px;
          padding: 1px;
          display: flex;
          flex-direction: column;

          .modal-input-box {
            width: 100%;

            .input-wrapper {
              width: 100%;
              position: relative;
              
              .modal-input {
                margin-top: 5px;
                padding: 18px 20px;
                width: 100%;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;
                border: 1px solid transparent;
                background-color: $color-white;
                @include golos;
                font-weight: 400;
                font-size: $fz-s;
                line-height: 20px;
                color: $color-stormy-sea;
            
                @media (max-width: $max-width-mobile) {
                  font-size: $fz-xs;
                  line-height: 1.2;
                }
              }
              .input-pencil-box {
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 10;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                cursor: pointer;

                img {
                  height: 20px;
                  width: auto;
                }
              }
            }
          }
        }
        // Блок - эл.почта
        .account__email {
          max-width: 360px;
          width: 100%;
          height: 115px;
          padding: 1px;
          display: flex;
          flex-direction: column;

          .modal-input-box {
            width: 100%;

            .input-wrapper {
              width: 100%;
              position: relative;
              
              .modal-input {
                margin-top: 5px;
                padding: 18px 20px;
                width: 100%;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;
                border: 1px solid transparent;
                background-color: $color-white;
                @include golos;
                font-weight: 400;
                font-size: $fz-s;
                line-height: 20px;
                color: $color-stormy-sea;
            
                @media (max-width: $max-width-mobile) {
                  font-size: $fz-xs;
                  line-height: 1.2;
                }
              }
              .input-pencil-box {
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 10;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                cursor: pointer;

                img {
                  height: 20px;
                  width: auto;
                }
              }
            }
          }
        }
        @media (max-width: $max-width-tablet) {
          max-width: 360px;
          margin: 0 auto;
          margin-bottom: 30px;
        }
        @media (max-width: $max-width-mobile) {
          max-width: 320px;
          min-width: 300px;
          margin: 0;
          margin-bottom: 30px;
        }
      }
      // 2 - Настройки уведомлений и другое
      .account__customizing {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-width: 290px;
        max-width: 25%;

        .account__face-entry {
          display: flex;
          justify-content: space-between;
          gap: 5px;
          width: 100%;
          height: 22px;
          margin-bottom: 50px;

          .text-box {
            display: flex;
            align-items: center;
            flex: 0 0 auto;         /* Занимает только необходимое пространство */
            height: 100%;
            padding: 1px;

            p {
              font-size: $fz-s;
            }
          }
          @media (max-width: $max-width-tablet) {
            margin-bottom: 20px;
          }
        }
        .account__text-notifications-box {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          height: 22px;
          margin-bottom: 30px;

          p {
            font-size: $fz-s;
          }
          @media (max-width: $max-width-tablet) {
            margin-bottom: 10px;
          }
        }
        .account__customizing-down {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          width: 100%;

          .account__customizing-item {
            display: flex;
            justify-content: space-between;
            gap: 5px;
            width: 100%;
            height: 22px;
  
            .text-box {
              display: flex;
              align-items: center;
              flex: 0 0 auto;         /* Занимает только необходимое пространство */
              height: 100%;
              padding: 1px;
  
              p {
                font-size: $fz-xs;
              }
            }
          }
          .account__method-of-receipt {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 5px;
            width: 100%;
            height: 22px;

            .text-box {
              display: flex;
              align-items: center;
              height: 100%;
              padding: 1px;
  
              p {
                font-size: $fz-xs;
              }
            }
            .check-boxes-wrapper {
              display: flex;
              gap: 15px;

              .check-boxes-item {
                display: flex;
                align-items: center;
                gap: 5px;

                p {
                  font-size: $fz-xs;
                }
              }
            }
          }
          @media (max-width: $max-width-tablet) {
            gap: 20px;
          }
        }
        @media (max-width: $max-width-tablet) {
          margin: 0 auto 50px;
          max-width: 360px;
        }
        @media (max-width: $max-width-mobile) {
          max-width: 320px;
          min-width: 300px;
          margin: 0;
          margin-bottom: 30px;
        }
      }
      // 3 - Подключение способов оплаты
      .account__payment-methods {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-width: 360px;
        max-width: 30%;

        .account__payment-methods-left {
          min-width: 360px;

          p {
            font-size: $fz-s;
          }
          // Блок в котом все подключенные карты и кнопка Добавить карту
          .account__payment-methods-box {
            margin-top: 20px;
            height: 100%;

            // Строка - Карта и кнопка удалить
            .account__payment-method-item {
              display: flex;
              gap: 5px;
              margin-bottom: 20px;

              .btn-garbage-box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                min-height: 70px;
                width: 25px;

                img {
                  height: 25px;
                  width: auto;
                  cursor: pointer;
                }
              }
            }
            .account__payment-method-add-card {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 330px;
              min-height: 70px;
              padding: 10px;
              border-radius: 10px;
              border-style: none;
              background-color: $color-light-blue07;
              cursor: pointer;

              .payment__add-card-box {

                p {
                  font-size: $fz-s;
                  opacity: 0.5;
                }
              }
              @media (max-width: $max-width-mobile) {
                max-width: 320px;
                min-width: 300px;
                margin: 0;
                margin-bottom: 30px;
              }
            }
            .account__payment-method-add-card:hover {
              .payment__add-card-box {
                p {
                  font-size: $fz-s;
                  opacity: 0.8;
                }
              }
            }
            .account__payment-method-add-card:active {
              .payment__add-card-box {
                p {
                  font-size: $fz-s;
                  opacity: 1;
                }
              }
            }
          }
          // @media (max-width: $max-width-mobile) {
          //   width: 100%;
          //   max-width: 100%;
          // }
          @media (max-width: $max-width-mobile) {
            max-width: 320px;
            min-width: 300px;
            margin: 0;
            margin-bottom: 30px;
          }
        }
        @media (max-width: $max-width-tablet) {
          margin-top: 30px;
          max-width: 360px;
          margin: 0 auto;
        }
        @media (max-width: $max-width-mobile) {
          max-width: 320px;
          min-width: 300px;
          margin: 0;
          margin-bottom: 30px;
        }
      }
      @media (max-width: $max-width-tablet) {
        flex-direction: column;
      }
      @media (max-width: $max-width-mobile) {
        align-items: center;
      }
    }
    .account__place-down {
      min-height: 80px;
      height: 100%;
      max-height: 25%;
      display: flex;
      justify-content: center;
      align-items: center;

      .account__btn-delete-account-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 1px;
        cursor: pointer;
        height: 35px;

        .image-box {
          height: 35px;
          width: 30px;

          img {
            width: 100%;
            height: auto;
            position: relative;
            z-index: 1;
            mix-blend-mode: multiply;
            opacity: 0.8;
          }
        }
        .text-box {
          height: 35px;

          p {
            font-size: $fz-m;
          }
        }
        
      }
    }
    @media (max-width: $max-width-mobile) {
      margin-top: 20px;
    }
  }
}