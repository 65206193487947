@import "../App/var";
@import "../App/App.scss";

.mini-carousel-item {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 20px;
  border-style: none;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  @media (max-width: $max-width-mobile) {
    width: 110px;
    min-width: 110px;
    height: 110px;
  }
}