.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 46vh;
}
.burger-menu .burger-menu__logo-box {
  width: 95px;
  height: auto;
  margin-bottom: 2vh;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__logo-box {
    width: 58px;
  }
}
.burger-menu .burger-menu__menu-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  margin-bottom: 2vh;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__menu-box .burger-menu__menu-item p {
    font-size: 16px;
  }
}
.burger-menu .burger-menu__log-in-box,
.burger-menu .burger-menu__log-out-box {
  margin-bottom: 2vh;
}
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out {
  display: flex;
  align-items: center;
  gap: 15px;
}
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-in-image-box,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-out-image-box,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-in-image-box,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-out-image-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-in-image-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-out-image-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-in-image-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-out-image-box {
  width: 30px;
  height: auto;
}
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-in-image-box img,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-out-image-box img,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-in-image-box img,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-out-image-box img,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-in-image-box img,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-out-image-box img,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-in-image-box img,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-out-image-box img {
  width: 100%;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-in-image-box,
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-out-image-box,
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-in-image-box,
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-out-image-box,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-in-image-box,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-out-image-box,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-in-image-box,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-out-image-box {
    width: 20px;
    height: auto;
  }
}
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-in-text-box,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-out-text-box,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-in-text-box,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-out-text-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-in-text-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-out-text-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-in-text-box,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-out-text-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-in-text-box p,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-out-text-box p,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-in-text-box p,
.burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-out-text-box p,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-in-text-box p,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-out-text-box p,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-in-text-box p,
.burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-out-text-box p {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-in-text-box p,
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in .burger-menu__log-out-text-box p,
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-in-text-box p,
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out .burger-menu__log-out-text-box p,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-in-text-box p,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in .burger-menu__log-out-text-box p,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-in-text-box p,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out .burger-menu__log-out-text-box p {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-in,
  .burger-menu .burger-menu__log-in-box .btn-burger-menu__log-out,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-in,
  .burger-menu .burger-menu__log-out-box .btn-burger-menu__log-out {
    gap: 10px;
  }
}
.burger-menu .burger-menu__contacts {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 2vh;
}
.burger-menu .burger-menu__contacts p {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__contacts p {
    font-size: 14px;
    font-weight: 700;
  }
}
.burger-menu .burger-menu__contacts .burger-menu__contacts-item-box .burger-menu__contacts-item {
  display: flex;
  gap: 15px;
}
.burger-menu .burger-menu__contacts .burger-menu__contacts-item-box .burger-menu__contacts-item .burger-menu__contacts-icon-box {
  width: 24px;
  height: auto;
}
.burger-menu .burger-menu__contacts .burger-menu__contacts-item-box .burger-menu__contacts-item .burger-menu__contacts-icon-box img {
  width: 100%;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__contacts .burger-menu__contacts-item-box .burger-menu__contacts-item .burger-menu__contacts-icon-box {
    width: 15px;
  }
}
.burger-menu .burger-menu__contacts .burger-menu__contacts-item-box .burger-menu__contacts-item p {
  font-size: 18px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__contacts .burger-menu__contacts-item-box .burger-menu__contacts-item p {
    font-size: 16px;
  }
}
.burger-menu .burger-menu__socials {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.burger-menu .burger-menu__socials p {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__socials p {
    font-size: 14px;
    font-weight: 700;
  }
}
.burger-menu .burger-menu__socials .burger-menu__socials-box {
  display: flex;
  gap: 25px;
}
.burger-menu .burger-menu__socials .burger-menu__socials-box .burger-menu__socials-item {
  height: 41px;
  width: auto;
}
.burger-menu .burger-menu__socials .burger-menu__socials-box .burger-menu__socials-item img {
  height: 100%;
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__socials .burger-menu__socials-box .burger-menu__socials-item {
    height: 30px;
  }
}
@media (max-width: 767px) {
  .burger-menu .burger-menu__socials .burger-menu__socials-box {
    gap: 20px;
  }
}/*# sourceMappingURL=BurgerMenu.css.map */