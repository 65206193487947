@charset "UTF-8";
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI-VF";
  src: url("/src/fonts/Golos-UI-VF-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Medium-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "MV-WEEKEND";
  src: url("/src/fonts/MV-WEEKEND.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
html {
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

body {
  font-weight: 400;
  color: rgb(69, 86, 92);
  height: 100%;
  width: 100vw;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img {
  display: block;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  padding: 0;
  margin: 0;
}

h2, h3, h4, h5, h6, p, ul, li {
  font-family: "Golos-UI", sans-serif;
}

p {
  font-size: 20px;
  line-height: 1.2;
  color: rgb(69, 86, 92);
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  border-style: none;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.opacity30 {
  opacity: 0.3;
}

.opacity30:hover {
  opacity: 0.7;
}

.opacity30:active {
  opacity: 1;
}

.opacity50 {
  opacity: 0.5;
}

.opacity50:hover {
  opacity: 0.65;
}

.opacity60 {
  opacity: 0.6;
}

.opacity60:hover {
  opacity: 1;
}

.opacity70 {
  opacity: 0.7;
}

.opacity70:hover {
  opacity: 1;
}

.container-m {
  max-width: 950px;
  width: 100%;
}

.btn-modal {
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
}
.btn-modal p {
  font-family: "Golos-UI", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  color: rgb(238, 251, 255);
}
@media (max-width: 767px) {
  .btn-modal p {
    font-size: 20px;
    line-height: 1.2;
  }
}

/* Исправляем возможные расхождения через медиазапросы для iOS */
/* Специальные стили для iOS Safari */
.btn-standard {
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
}
.btn-standard p {
  font-family: "Golos-UI", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  color: rgb(69, 86, 92);
}
@media (max-width: 767px) {
  .btn-standard p {
    font-size: 20px;
    line-height: 1.2;
  }
}

.button-v2 {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
.button-v2 p {
  color: rgb(238, 251, 255);
  font-size: 14px;
  line-height: 1;
}

.button-v2.not-active {
  background-color: rgba(116, 140, 149, 0.5);
  pointer-events: none;
}

.button-v2.active {
  background-color: rgb(116, 140, 149);
}

.button-v2.active:hover {
  border-color: rgb(69, 86, 92);
}

.button-v2.active:active {
  background-color: rgb(69, 86, 92);
  border-color: rgb(69, 86, 92);
}

/* Микс стиль - Кнопка Из тёмнго фона в светлый */
.btn-dark-to-light {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, #EEFBFF -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, #EEFBFF 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-dark-to-light p {
  color: rgb(238, 251, 255);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: color 0.5s ease;
}

.btn-dark-to-light * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-dark-to-light:hover {
  opacity: 0.8;
}

.btn-dark-to-light:active {
  background-image: linear-gradient(270.96deg, #EEFBFF -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, #EEFBFF 106.06%);
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(215, 244, 251, 0.6);
}
.btn-dark-to-light:active p {
  color: rgb(238, 251, 255);
}

.btn-dark {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 3px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  transition: background-color 1s;
  display: inline-block;
  flex-grow: 0;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-dark p {
  font-family: "Golos-UI", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  color: rgb(238, 251, 255);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: color 0.5s ease;
}
@media (max-width: 767px) {
  .btn-dark p {
    font-size: 20px;
    line-height: 1.2;
  }
}

.btn-dark * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-dark:hover {
  background-image: linear-gradient(rgb(108, 118, 121), rgb(108, 118, 121));
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
}

.btn-dark:active {
  background-image: linear-gradient(rgb(69, 86, 92), rgb(69, 86, 92));
  box-shadow: inset 0 100vw rgb(69, 86, 92);
}

.btn-dark.btn-disabled,
.btn-dark.btn-disabled:hover,
.btn-dark.btn-disabled:active {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  /* Цвет внутренней часть кнопки */
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  opacity: 0.5;
}

/* Микс стиль - Кнопка Из светлого фона в тёмный */
.btn-light-to-dark {
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(215, 244, 251, 0.6);
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-light-to-dark * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-light-to-dark:hover {
  box-shadow: inset 0 100vw rgba(194, 220, 226, 0.6);
}

.btn-light-to-dark p {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-light-to-dark:active {
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  background-origin: border-box;
  box-shadow: inset 0 100vw rgb(116, 140, 149);
}

.btn-light-to-dark:active p {
  color: rgb(238, 251, 255);
}

.btn-disabled {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(116, 140, 149, 0.5), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.link-disabled {
  cursor: default !important;
  opacity: 0.5;
}

.btn-padding-10-20 {
  padding: 10px 20px;
}

.btn-padding-10-40 {
  padding: 10px 40px;
}

.modal-input-box .modal-label,
.input__box .modal-label {
  font-family: "Golos-UI", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: rgb(69, 86, 92);
}
@media (max-width: 767px) {
  .modal-input-box .modal-label,
  .input__box .modal-label {
    font-size: 16px;
    line-height: 1.2;
  }
}
.modal-input-box .modal-input,
.input__box .modal-input {
  margin-top: 5px;
  padding: 18px 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid transparent;
  background-color: rgb(255, 255, 255);
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: rgb(50, 87, 96);
  position: relative;
}
@media (max-width: 767px) {
  .modal-input-box .modal-input,
  .input__box .modal-input {
    font-size: 16px;
    line-height: 1.2;
  }
}
.modal-input-box .modal-input-wrong,
.input__box .modal-input-wrong {
  background-color: rgb(255, 225, 225);
}
.modal-input-box .modal-input:focus,
.input__box .modal-input:focus {
  border: 1px solid rgba(113, 137, 143, 0.5);
  outline: none;
}
.modal-input-box .modal-input::-moz-placeholder, .input__box .modal-input::-moz-placeholder {
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: rgba(50, 87, 96, 0.35);
}
.modal-input-box .modal-input::placeholder,
.input__box .modal-input::placeholder {
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: rgba(50, 87, 96, 0.35);
}
@media (max-width: 767px) {
  .modal-input-box .modal-input::-moz-placeholder, .input__box .modal-input::-moz-placeholder {
    font-size: 16px;
    line-height: 1.2;
  }
  .modal-input-box .modal-input::placeholder,
  .input__box .modal-input::placeholder {
    font-size: 16px;
    line-height: 1.2;
  }
}
.modal-input-box .hint-box p,
.input__box .hint-box p {
  font-family: "Golos-UI", sans-serif;
  font-size: 18px;
  color: rgb(69, 86, 92);
}
@media (max-width: 767px) {
  .modal-input-box .hint-box p,
  .input__box .hint-box p {
    font-size: 16px;
    line-height: 1.2;
  }
}

.password-box {
  position: relative;
}

.show-dont-show-password {
  position: absolute;
  z-index: 4;
  bottom: 18px;
  right: 22px;
  border-style: none;
  background: transparent;
  padding: 0;
  height: 24px;
  width: auto;
}
.show-dont-show-password img {
  height: 100%;
  width: auto;
}

.wrong-data-box {
  display: flex;
  justify-content: center;
  padding: 1px;
  margin-top: 3px;
}
.wrong-data-box p {
  font-size: 16px;
  color: rgb(209, 59, 59);
}

.like-modal-blur {
  border-radius: 20px;
  border: 3px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.7), rgba(238, 251, 255, 0.7)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

/* стили Чек-бокс(Архивные проекты) */
.projects__form-archive-box {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 5px;
  padding: 5px 0px;
}

.projects__form-archive-box p {
  font-size: var(--fz-xs);
  margin-top: 5px;
}

/* Чек-бокс квадратный */
.image__checkbox-square-select-photo-box {
  width: 24px;
  height: 24px;
  position: relative;
}

.image__checkbox-square-select-photo {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  border-radius: 0px;
}

.custom-checkbox-square {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Скрываем стандартный чек-бокс */
.custom-checkbox-square-input {
  display: none;
}

/* Показываем картинку - кастомного чек-бокса */
.custom-checkbox-square-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/image-checkbox-square-uncheck.png");
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 0px;
}

/* Меняем изображение при активации чекбокса */
.custom-checkbox-square-input:checked + .custom-checkbox-square-icon {
  background-image: url("../../../assets/images/image-checkbox-square-checked.png");
}

.hr-gray {
  width: 100%;
  padding: 0;
  margin: 0;
  border-style: none;
  height: 1px;
  background-color: rgb(69, 86, 92);
}

.photographer-reg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.photographer-reg .photographer-reg__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
}
.photographer-reg .photographer-reg__wrapper .title-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.photographer-reg .photographer-reg__wrapper .title-description h2 {
  font-family: "MV-WEEKEND", sans-serif;
  font-size: 64px;
  line-height: 64px;
  color: rgb(238, 251, 255);
  text-align: center;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .photographer-reg .photographer-reg__wrapper .title-description h2 {
    font-family: "Golos-UI", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: rgb(69, 86, 92);
    text-transform: uppercase;
  }
}
.photographer-reg .photographer-reg__wrapper .title-description p {
  text-align: center;
}
@media (max-width: 767px) {
  .photographer-reg .photographer-reg__wrapper .title-description {
    margin-top: 50px;
  }
}
@media (max-width: 1199px) {
  .photographer-reg .photographer-reg__wrapper .title-description {
    margin-top: 50px;
  }
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 600px;
  width: 100%;
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .input-fio__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .input-fio__wrapper .input__box-three {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 190px;
  max-width: 31%;
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .element-box {
  display: flex;
  align-items: start;
  gap: 10px;
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .element-box .text-wrapper {
  padding-top: 5px;
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .element-box .text-wrapper .text-color {
  color: rgba(69, 86, 92, 0.7);
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .element-box .text-wrapper .link {
  color: rgb(116, 140, 149);
  text-decoration: underline;
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .element-box .text-wrapper .link:hover {
  cursor: pointer;
  color: rgba(50, 87, 96, 0.35);
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .text-box-bold p {
  font-weight: 500;
}
.photographer-reg .photographer-reg__wrapper .photographer-reg__form .bts-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}/*# sourceMappingURL=PhotographerReg.css.map */