@import '../../../App/var';
@import '../../../App/App.scss';

.cooperation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .cooperation__wrapper {
    display: flex;
    flex-direction: column;
    // max-width: 1560px;
    max-width: 90%;

    .cooperation__title {

      h2 {
        @include mv-weekend;
        font-size: $fz-xxxl2;
        line-height: 64px;
        color: $color-light-blue;
        text-align: center;
        margin-bottom: 50px;
  
        @media (max-width: $max-width-tablet) {
          margin-bottom: 20px;
        }
        @media (max-width: $max-width-mobile) {
          @include golos;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          color: $color-dark-blue-gray;
          text-transform: uppercase;
          margin-bottom: 0px;
        }
      }
      @media(max-width: $max-width-tablet) {
        margin-top: 30px;
      }
    }
    .cooperation__video-text {
      display: flex;
      flex-direction: row;
      gap: 50px;
      margin-bottom: 100px;
  
      .cooperation__video-box {
        display: inline-block;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 752px;
        min-width: 448px;
        height: fit-content; /* То же самое с высотой */
        position: relative;
    
        video {
          width: 100%;
          height: auto;
          border-radius: 20px;
          border-style: none;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: rgba(0, 0, 0, 0.5);

          img {
            width: 59px;
            height: auto;
            cursor: pointer;
          }
        }
        // @media (max-width: $max-width-tablet) {
        //   
        // }
        @media (max-width: $max-width-mobile) {
          min-width: 370px;
        }
      }
      .cooperation__text-box {
        flex-grow: 1;
        flex-shrink: 1.5;
        max-width: 750px;
        min-width: 400px;
    
        p {
          font-size: $fz-l;
          text-align: left;
          @media (max-width: $max-width-tablet) {
            text-align: center;
          }
          @media (max-width: $max-width-mobile) {
            font-size: 16px;
          }
        }
        .cooperation__btns-box {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          margin-top: 50px;
    
          p {
            text-transform: uppercase;
          }
          @media (max-width: $max-width-mobile) {
            margin-top: 20px;
            gap: 30px;
          }
        }
        @media (max-width: $max-width-tablet) {
          margin-bottom: 1vh;
        }
        @media(max-width: $max-width-mobile) {
          min-width: 320px;
        }
      }
      @media(max-width: $max-width-tablet) {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      @media(max-width: $max-width-mobile) {
        gap: 20px;
      }
    }
    .mini-carousel {
      display: flex;
      justify-content: center;
      height: 150px;
      margin-left: 50px;
      margin-right: 50px;

      .mini-carousel-photos-box {
        min-width: 400px;
        max-width: 1340px;
        height: 100%;
        position: relative;

        .mini-carousel-wrapper-photos {
          display: flex;
          flex-direction: row;
          gap: 20px;
          overflow: hidden;
          width: 100%;

          .mini-carousel-item {
            width: 150px;
            min-width: 150px;
            height: 150px;
            border-radius: 20px;
            border-style: none;
            overflow: hidden;
  
            img {
              width: 100%;
              height: 100%;
              object-fit: cover; /* Масштабируем изображение, обрезая его по краям, чтобы заполнить контейнер */
              object-position: center; /* Центрируем изображение в контейнере */
            }
            @media (max-width: $max-width-mobile) {
              width: 110px;
              min-width: 110px;
              height: 110px;
            }
          }
          @media(max-width: $max-width-mobile) {
            flex-wrap: wrap;
            max-height: 240px;
            justify-content: center;
          }
        }
        .mini-carousel__left-btn-box {
          position: absolute;
          top: 0px;
          left: -60px;
          width: 30px;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
  
          img {
            width: 100%;
            height: auto;
          }
          @media (max-width: $max-width-mobile) {
            display: none;
          }
        }
        .mini-carousel__right-btn-box {
          position: absolute;
          top: 0px;
          right: -60px;
          width: 30px;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
  
          img {
            width: 100%;
            height: auto;
            transform: rotate(180deg);
          }
          @media (max-width: $max-width-mobile) {
            display: none;
          }
        }
        @media(max-width: $max-width-tablet) {
          max-width: 830px;
        }
        @media (max-width: $max-width-mobile) {
          min-width: 370px;
          max-height: 240px;
        }
      }
      @media(max-width: $max-width-mobile) {
        max-height: 240px;
        height: 100%;
      }
    }  
    @media(max-width: $max-width-tablet) {
      max-width: 100%;
    }
  }
}