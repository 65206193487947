@import "../../../App/var";
@import "../../../App/App.scss";

.cart {
  // height: 100%;
  // margin-bottom: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;

  // Header
  .cart__top {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    margin-right: 30px;
    margin-bottom: 50px;
    margin-left: 50px;

    // Блок с Названием раздела
    .cart__top-title-box {
      display: flex;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: $fz-xl;
        font-weight: 500;

        @media (max-width: $max-width-mobile) {
          font-size: $fz-m;
        }
      }
      p {
        font-size: $fz-m;
        font-weight: 400;

        @media (max-width: $max-width-mobile) {
          font-size: $fz-xxs2;
        }

        .cart__top-title-photos-count {
          font-size: $fz-m;
          font-weight: 700;

          @media (max-width: $max-width-mobile) {
            font-size: $fz-xxs2;
            font-weight: 500;
          }
        }
      }
      @media (max-width: $max-width-mobile) {
        align-items: flex-end;
      }
    }
    // Блок - кнопка выход
    .cart__top-right {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 5px;

      .cart__top-button-image-box {
        height: 26px;
        width: 100%;
      }
      @media (max-width: $max-width-tablet) {
        display: none;
      }
      @media (max-width: $max-width-mobile) {
        display: none;
      }
    }
    @media (max-width: $max-width-tablet) {
      margin-right: 30px;
      margin-left: 30px;
    }
    @media (max-width: $max-width-mobile) {
      margin-top: 10px;
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  .cart__content {
    margin-right: 30px;
    margin-left: 50px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    height: 100%;
    width: 100%;

    // Пустая корзина
    .cart__empty {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .cart__empty-title {

        h2 {
          @include mv-weekend;
          font-size: $fz-xxxl2;
          line-height: 70px;
          color: $color-light-blue;
          text-align: center;
          margin-bottom: 50px;
    
          @media (max-width: $max-width-tablet) {
            margin-bottom: 20px;
          }
          @media (max-width: $max-width-mobile) {
            font-size: 45px;
            line-height: 36px;
            margin-bottom: 20px;
          }
        }
      }
      .cart__empty-image-box {
        display: flex;
        justify-content: center;
        margin-bottom: 3vh;
    
        img {
          max-height: 370px;
          height: 100%;
          width: auto;
          position: relative;
          z-index: 1;
          mix-blend-mode: multiply;
          opacity: 0.8;
          margin-bottom: 0px;
        }
        @media (max-width: $max-width-tablet) {
          max-height: 30vh;
        }
        @media (max-width: $max-width-mobile) {
          max-height: 28vh;
        }
      }
      .cart__empty-btn-box {
    
        p {
          text-transform: uppercase;
        }
      }
    
      @media (max-width: $max-width-tablet) {
        justify-content: center;
    
        .cart__empty-title {
          order: 2;
        }
        .cart__empty-image-box {
          order: 1;
        }
        .cart__empty-btn-box {
          order: 4;
        }
      }
    }

    @media (max-width: $max-width-tablet) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 40px;
      margin-right: 30px;
      margin-left: 30px;
    }
    @media (max-width: $max-width-mobile) {
      margin-right: 0px;
      margin-left: 0px;
      gap: 10px;
    }
  }
  @media (max-width: $max-width-tablet) {
    padding-bottom: 0;
  }
}
