.global-modal {
  z-index: 100;
}

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 7vh;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.modal .modal__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 28px 20px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.modal .modal__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .modal .modal__content {
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 30px 20px 20px;
  }
}
@media (max-width: 1199px) {
  .modal {
    padding-top: 12vh;
  }
}
@media (max-width: 767px) {
  .modal {
    padding-top: 1vh;
  }
}

.modal.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.burger {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: start;
  align-items: start;
  padding-top: 40px;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.burger .burger__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 80px 20px;
  margin-left: 50px;
  border-radius: 20px;
  max-width: 420px;
  max-height: 99vh;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.burger .burger__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .burger .burger__content {
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 20px 45px 20px;
  }
}
@media (max-width: 767px) {
  .burger {
    padding-top: 30px;
  }
}

.burger.active {
  opacity: 1;
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
}

.confirm {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: flex-end;
  align-items: start;
  padding-top: 35px;
  padding-right: 40px;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.confirm .confirm__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 60px 30px;
  border-radius: 20px;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.confirm .confirm__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .confirm .confirm__content {
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 30px 20px 20px;
  }
}
@media (max-width: 767px) {
  .confirm {
    justify-content: center;
    padding-top: 15vh;
    padding-right: 0;
  }
}

.confirm.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.success {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.success .success__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 30px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.success .success__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .success .success__content {
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 50px 10px;
  }
}
.success.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.cooperation_modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.cooperation_modal .cooperation_modal__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 28px 20px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.cooperation_modal .cooperation_modal__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .cooperation_modal .cooperation_modal__content {
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 30px 20px 20px;
  }
}
@media (max-width: 1199px) {
  .cooperation_modal {
    padding-top: 12vh;
  }
}
@media (max-width: 767px) {
  .cooperation_modal {
    padding-top: 1vh;
  }
}

.cooperation_modal.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.galleryprofile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 39;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.galleryprofile .galleryprofile__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 35px 0px 0px;
  border-radius: 50px 20px 20px 50px;
  max-height: 90vh;
  margin-top: 165px;
  margin-left: 148px;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
  overflow: hidden;
}
.galleryprofile .galleryprofile__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .galleryprofile .galleryprofile__content .close-btn-box {
    top: 12px;
    right: 12px;
    width: 13px;
    height: auto;
  }
}
@media (max-width: 1199px) {
  .galleryprofile .galleryprofile__content {
    margin-top: 102px;
    margin-left: 20px;
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .galleryprofile .galleryprofile__content {
    max-width: 98vw;
    margin-top: 124px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 30px 0px 0px;
    border-radius: 50px 50px 50px 50px;
  }
}
@media (max-width: 1199px) {
  .galleryprofile {
    padding-top: 12vh;
  }
}
@media (max-width: 767px) {
  .galleryprofile {
    padding-top: 1vh;
  }
}

.galleryprofile.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.confirm_cart_photo_delete {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.confirm_cart_photo_delete .confirm_cart_photo_delete__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 30px 30px;
  border-radius: 20px;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.confirm_cart_photo_delete .confirm_cart_photo_delete__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .confirm_cart_photo_delete .confirm_cart_photo_delete__content {
    min-width: 370px;
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 30px 20px 20px;
  }
}
@media (max-width: 767px) {
  .confirm_cart_photo_delete {
    justify-content: center;
    padding-top: 15vh;
    padding-right: 0;
  }
}

.confirm_cart_photo_delete.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.photo_carousel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.photo_carousel .photo_carousel__content {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.photo_carousel .photo_carousel__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 2px;
  right: 2px;
}
@media (max-width: 767px) {
  .photo_carousel .photo_carousel__content .close-btn-box {
    top: 15px;
    right: 15px;
  }
}

.photo_carousel.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.coop_reg_success {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.coop_reg_success .coop_reg_success__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 28px 20px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.coop_reg_success .coop_reg_success__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .coop_reg_success .coop_reg_success__content {
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 30px 20px 20px;
  }
}
@media (max-width: 1199px) {
  .coop_reg_success {
    padding-top: 12vh;
  }
}
@media (max-width: 767px) {
  .coop_reg_success {
    padding-top: 1vh;
  }
}

.coop_reg_success.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.error_payment_modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;
}
.error_payment_modal .error_payment_modal__content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 28px 20px;
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  border: 2px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.0509803922);
}
.error_payment_modal .error_payment_modal__content .close-btn-box {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 41;
  top: 7px;
  right: 7px;
}
@media (max-width: 767px) {
  .error_payment_modal .error_payment_modal__content {
    max-width: 450px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 30px 20px 20px;
  }
}
@media (max-width: 1199px) {
  .error_payment_modal {
    padding-top: 12vh;
  }
}
@media (max-width: 767px) {
  .error_payment_modal {
    padding-top: 1vh;
  }
}

.error_payment_modal.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}/*# sourceMappingURL=Modal.css.map */