@import "../../../../../client/src/Components/App/App.css";
.confirm-cart-photo-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirm-cart-photo-delete p {
  font-size: 20px;
  max-width: 240px;
  text-align: center;
}
.confirm-cart-photo-delete .confirm-cart-photo-delete__buttons-box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
}/*# sourceMappingURL=ConfirmCartPhotoDelete.css.map */