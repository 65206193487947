@import "../App/var";
@import "../App/App.scss";

.place {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;

  .place__photos-box {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  @media (max-width: $max-width-mobile) {
    margin-top: 30px;
  }
}