@import '../../App/App.scss';

.authorization {
  display: flex;
  flex-direction: column;

  .modal-title, .modal-title-2 {
    font-size: $fz-m2;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-m;
    }
  }
  .modal-title {
    margin-bottom: 4vh;

    @media (max-width: $max-width-mobile) {
      margin-bottom: 2.5vh;
    }
  }
  .modal-title-2 {
    margin-top: 3.7vh;
  }
  .modal-social-box {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 4.5vh;
    margin-bottom: 6.5vh;
    
    .btn-social-login {
      width: 60px;
      height: auto;
    }
  }
  .modal-submit-box {
    display: flex;
    justify-content: center;
    margin-top: 2.8vh;

    @media (max-width: $max-width-mobile) {
      margin-top: 1.5vh;
    }
  }
  .authorization__enter-code-box {
    .authorization__confirm-code-box {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 1.7vh;
  
      .modal-code-input {
        background-color: transparent;
        width: 50px;
        height: 70px;
        @include golos;
        font-size: $fz-xxxl2;
        line-height: 1.1;
        color: $color-dark-blue-gray;
        text-align: center;
        padding: 5px;
        border-top: none;
        border-right: none;
        border-bottom: 2px solid $color-dark-blue-gray;
        border-left: none;
      }
      .modal-code-input::-webkit-inner-spin-button, 
      .modal-code-input::-webkit-outer-spin-button {
        display: none
      }
      .modal-code-input-wrong {
        color: $color-warning;
      }
      .modal-code-input:focus {
        border-bottom: 2px solid $color-dark-blue-gray;
        outline: none;
      }

      @media (max-width: $max-width-mobile) {
        margin-top: 1.2vh;
      }
    }
    .authorization__counter-box {
      display: flex;
      justify-content: center;
      margin-top: 2.5vh;
      margin-bottom: 2vh;
  
      p {
        font-size: $fz-xs;
  
        span {
          font-weight: 700;
        }
      }

      @media (max-width: $max-width-mobile) {
        margin-bottom: 0.5vh;
      }
    }
    .authorization__btn-again-code-box {
      display: flex;
      justify-content: center;
    }
    .authorization__code-info-box {
      display: flex;
      justify-content: center;
      margin-top: 1.4vh;

      p {
        font-size: $fz-xs;
        text-align: center;
        max-width: 392px;

        button {

          p {
            text-decoration: underline;
          }
        }
      }
    }
  }
 
  .modal-agreed-box {
    display: flex;
    justify-content: center;
    margin-top: 3.7vh;

    p {
      font-size: $fz-xs;
      text-align: center;

      button {
        p {
          text-decoration: underline;
        }
      }
    }
  }
}
