@charset "UTF-8";
@import "../../App/App.css";
.photo-carousel {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.photo-carousel .photo-carousel__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.photo-carousel .photo-carousel__top .photo-carousel__title-box {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 20px;
  padding: 1px;
  width: 100%;
  max-width: calc(100vw - 160px);
}
.photo-carousel .photo-carousel__top .photo-carousel__title-box p {
  font-size: 16px;
  text-align: center;
}
@media (max-width: 1199px) {
  .photo-carousel .photo-carousel__top .photo-carousel__title-box p {
    padding-left: 15px;
    padding-right: 30px;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__title-box p {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 30px;
    text-align: left;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__title-box .photo-carousel__title-date {
  font-weight: 700;
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__title-box .photo-carousel__title-date {
    font-weight: 400;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__title-box .photo-carousel__title-dop-box {
  display: none;
}
@media (max-width: 1199px) {
  .photo-carousel .photo-carousel__top .photo-carousel__title-box .photo-carousel__title-dop-box {
    display: flex;
    margin-top: 30px;
  }
}
@media (max-width: 1199px) {
  .photo-carousel .photo-carousel__top .photo-carousel__title-box {
    width: 100%;
    margin-top: 45px;
    min-height: 50px;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__title-box {
    width: 100%;
    margin-top: 45px;
    min-height: 80px;
    max-width: 100%;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 97%;
  max-width: 97%;
  padding: 1px;
  position: relative;
  background-color: rgba(69, 86, 92, 0.5);
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box {
  position: absolute;
  bottom: 50%;
  left: 0px;
  transform: translateY(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 100px 5px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.5s ease;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box .photo-carousel__photo-arrow-left {
  width: 80px;
  height: auto;
  opacity: 0.7;
  transition: opacity 0.5s ease;
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box .photo-carousel__photo-arrow-left {
    width: 30px;
  }
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box {
    padding: 30px 5px;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box:hover {
  background-color: rgba(255, 255, 255, 0.7);
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box:hover .photo-carousel__photo-arrow-left {
  opacity: 1;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box:active {
  background-color: rgb(255, 255, 255);
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-left-box:active .photo-carousel__photo-arrow-left {
  opacity: 1;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box {
  position: absolute;
  bottom: 50%;
  right: 0px;
  transform: translateY(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 100px 5px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.5s ease;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box .photo-carousel__photo-arrow-right {
  width: 80px;
  height: auto;
  opacity: 0.7;
  transition: opacity 0.5s ease;
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box .photo-carousel__photo-arrow-right {
    width: 30px;
  }
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box {
    padding: 30px 5px;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box:hover {
  background-color: rgba(255, 255, 255, 0.7);
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box:hover .photo-carousel__photo-arrow-right {
  opacity: 1;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box:active {
  background-color: rgb(255, 255, 255);
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .btn-arrow-right-box:active .photo-carousel__photo-arrow-right {
  opacity: 1;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box {
  /* Кастомный чекбокс - Избранное */
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .image__checkbox-select-photo {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .image__checkbox-select-photo {
    top: 5px;
    right: 5px;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .image__checkbox-favourites-photo {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .image__checkbox-favourites-photo {
    bottom: 5px;
    right: 5px;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .image__checkbox-download-photo {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .image__checkbox-download-photo {
    bottom: 5px;
    right: 5px;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  /* Меняем изображение при активации чекбокса */
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox .custom-checkbox-input {
  display: none;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox .custom-checkbox-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/image-checkbox-uncheck.png");
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 25px;
  opacity: 0.5;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox .custom-checkbox-input:checked + .custom-checkbox-icon {
  background-image: url("../../../assets/images/image-checkbox-checked.png");
  opacity: 1;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-favourites {
  display: flex;
  align-items: center;
  gap: 5px;
  /* Стили для кастомной галочки */
  /* Меняем изображение при активации чекбокса */
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-favourites .custom-checkbox-input-favourites {
  display: none;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-favourites .custom-checkbox-icon-favourites {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/image-checkbox-uncheck-favourite.svg");
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 25px;
  opacity: 0.5;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-favourites .custom-checkbox-input-favourites:checked + .custom-checkbox-icon-favourites {
  background-image: url("../../../assets/images/image-checkbox-favourites-v2.svg");
  opacity: 1;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-download {
  display: flex;
  align-items: center;
  gap: 5px;
  /* Стили для кастомной галочки */
  /* Меняем изображение при активации чекбокса */
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-download .custom-checkbox-input-download {
  display: none;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-download .custom-checkbox-icon-download {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/image-checkbox-uncheck.png");
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 25px;
}
.photo-carousel .photo-carousel__top .photo-carousel__photo-box .image__checkbox-select-photo-box .custom-checkbox-download .custom-checkbox-input-download:checked + .custom-checkbox-icon-download {
  background-image: url("../../../assets/images/image-checkbox-download.svg");
}
.photo-carousel .photo-carousel__top .photo-carousel__info-box {
  display: flex;
  flex-shrink: 0;
  max-width: 740px;
  padding: 1px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.photo-carousel .photo-carousel__top .photo-carousel__info-box p {
  font-size: 16px;
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__info-box p {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .photo-carousel .photo-carousel__top .photo-carousel__info-box {
    display: none;
    padding-top: 0px;
  }
}
@media (max-width: 767px) {
  .photo-carousel .photo-carousel__top .photo-carousel__info-box {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.photo-carousel .photo-carousel__top .photo-carousel__for-balance {
  display: none;
  height: 20px;
}
@media (max-width: 1199px) {
  .photo-carousel .photo-carousel__top .photo-carousel__for-balance {
    display: none;
    height: 50px;
  }
}
@media (max-width: 1199px) {
  .photo-carousel .photo-carousel__top .photo-carousel__title-box {
    order: 1;
  }
  .photo-carousel .photo-carousel__top .photo-carousel__photo-box {
    order: 3;
  }
  .photo-carousel .photo-carousel__top .photo-carousel__info-box {
    order: 2;
  }
  .photo-carousel .photo-carousel__top .photo-carousel__for-balance {
    order: 4;
  }
}
.photo-carousel .photo-carousel__footer {
  min-height: 80px;
  height: 80px;
}
@media (max-width: 1199px) {
  .photo-carousel {
    justify-content: center;
    align-items: center;
  }
}/*# sourceMappingURL=PhotoCarousel.css.map */