@import "../../App/var";

.gallery-profile-edit {
    // background-color: aqua;

    .gallery-profile-edit__top {
        display: flex;
        gap: 50px;

        .gallery-profile-edit__avatar-box {
            display: flex;
            align-items: center;
            gap: 5px;

            img {
                width: 83px;
                height: auto;

                @media (max-width: $max-width-mobile) {
                    width: 50px;
                }
            }
            p {
                font-size: $fz-s;
                font-weight: 500;

                @media (max-width: $max-width-mobile) {
                    display: none;
                }
            }
        }
        .gallery-profile-edit__top-right {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .gallery-profile-edit__name-box {
                display: none;
                justify-content: center;
                
                p {
                    font-size: $fz-xxs;
                    font-weight: 500;
                }

                @media (max-width: $max-width-mobile) {
                    display: flex;
                }

            }
            .gallery-profile-edit__buttons-box {
                display: flex;
                gap: 20px;

                .gallery-profile-edit__button-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    img {
                        width: 15px;
                        height: 15px;
                        margin-bottom: 3px;

                        @media (max-width: $max-width-mobile) {
                            width: 10px;
                            height: 10px;
                            margin-bottom: 2px;
                        }
                    }
                    p {
                        font-size: $fz-xs;

                        .gallery-profile-edit__button-text-garbage {

                            @media (max-width: $max-width-mobile) {
                                display: none;
                            }
                        }
                        @media (max-width: $max-width-mobile) {
                            font-size: $fz-xxs2;
                        }
                    }
                }
                .gallery-profile-edit__button-item:hover {
                    cursor: pointer;
                }
                @media (max-width: $max-width-mobile) {
                    gap: 15px;
                }
            }
            .gallery-profile-edit__input-box {

                .gallery-profile-edit__text-new-name {
                    display: none;

                    p {
                        font-size: $fz-xs;
                    }
                    @media (max-width: $max-width-mobile) {
                        display: flex;
                    }
                }
                .gallery-profile-edit__input-and-button {
                    display: flex;
                    gap: 20px;

                    input {
                        width: 370px;
                        height: 50px;
                        margin-top: 0;

                        @media (max-width: $max-width-tablet) {
                            width: 300px;
                            gap: 20px;
                        }
                    }
                    button {

                    }
                }
            }
            @media (max-width: $max-width-mobile) {
                gap: 7px;
            }
        }
        @media (max-width: $max-width-tablet) {
            gap: 20px;
        }
        @media (max-width: $max-width-mobile) {
            gap: 7px;
            
        }
    }
}
