@import "../../Components/App/var";
@import "../../Components/App/App.scss";

.select-photographer-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 450px;

}
.form-select-photographer {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 350px;
  min-width: 290px;
  width: 100%;
  height: 100%;
  background-color: rgba(224, 243, 248, 1);

}

.form-select-photographer-select {
  border-style: none;
  background: transparent;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;

}
.form-select-photographer-select:hover {
  cursor: pointer;
}
select {
  font-size: 20px !important;
  line-height: 1.2;
  color: rgb(69, 86, 92);
  font-family: "Golos-UI", sans-serif;

}
option {
  font-size: 20px !important;
  line-height: 1.2;
  color: rgb(69, 86, 92);
  font-family: "Golos-UI", sans-serif;
  
}

.org-reg-acc-type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .org-reg-acc-type__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    max-width: 90%;
    // background-color: #f0aeae;

    .title-description {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-family: "MV-WEEKEND", sans-serif;
        font-size: 64px;
        line-height: 64px;
        color: rgb(238, 251, 255);
        text-align: center;
        margin-bottom: 10px;

        @media (max-width: $max-width-mobile) {
          font-family: "Golos-UI", sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          color: rgb(69, 86, 92);
          text-transform: uppercase;
        }
      }
      p {
        text-align: center;
      }
    }
    .country-box {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .country-box-text {
        display: flex;
        justify-content: center;
      }
    }
    .text-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      .text-box-bold {
        display: flex;
        justify-content: center;

        p {
          font-weight: 500;
        }
      }
      p {
        text-align: center;
      }
    }
    .select-box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .btn-box {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        
        .btn-dark {
          .btn-text-span {
            @media (max-width: $max-width-mobile) {
              display: none;
            }
          }
        }
      }

      p {
        text-align: center;
      }
    }
  }
}