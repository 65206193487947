@import "../../../App/var";
@import "../../../App/App.scss";

.purchase {
  // background-color: #ec9999;
  width: 100%;
  height: 100%;

  // Успешная оплата
  .successful-payment {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .successful-payment__wrapper {
      width: 100%;
      max-width: 900px;
      padding: 1px;
      // background-color: #7c7a5e;

      .successful-payment__title {
        h2 {
          @include mv-weekend;
          font-size: $fz-xxxl2;
          color: $color-light-blue;
          text-align: center;
          margin-bottom: 100px;

          @media (max-width: $max-width-tablet) {
            
          }
          @media (max-width: $max-width-mobile) {
            @include golos;
            font-size: $fz-m;
            font-weight: 500;
            color: $color-dark-blue-gray;
            margin-bottom: 30px;
          }
        }
      }
      // Блок - картинки и кнопки
      .successful-payment__content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        // Левый - получить чек
        .successful-payment__left {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 430px;
          position: relative;
          // background-color: #80f1a6;

          .successful-payment__left-input-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 136px;
            width: 100%;
            max-width: 370px;

            .successful-payment__left-input-box-wrapper {
              display: flex;
              flex-direction: column;
            }
            @media (max-width: $max-width-mobile) {
              max-width: 360px;
            }
          }
          .successful-payment__left-button-box {
            margin-top: 30px;
          }
          .text-info-box {
            position: absolute;
            z-index: 10;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .btn-close-box {
              display: flex;
              justify-content: flex-end;
              height: 15px;
              margin-top: 10px;
              margin-left: 10px;
              margin-right: 10px;

              img {
                height: 100%;
                width: auto;
              }
            }

            p {
              text-align: center;
            }
            .balance-box {
              height: 15px;
            }
            @media (max-width: $max-width-tablet) {
              max-width: 430px;
              left: auto;
            }
            @media (max-width: $max-width-mobile) {
              max-width: 360px;
            }
          }
          @media (max-width: $max-width-mobile) {
            min-width: 360px;
          }
        }
        // Правый - перейти в галерею
        .successful-payment__right {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 430px;

          .successful-payment__right-image-box {
            display: flex;
            justify-content: center;

            img {
              height: 100%;
              max-height: 136px;
              width: auto;
              position: relative;
              z-index: 1;
              mix-blend-mode: multiply;
              opacity: 0.6;
            }
          }
          .successful-payment__right-button-box {
            margin-top: 30px;
          }
          @media (max-width: $max-width-mobile) {
            min-width: 360px;
            width: 100%;
          }
        }
        @media (max-width: $max-width-tablet) {
          flex-direction: column;
          justify-content: center;
          gap: 50px;
        }
        @media (max-width: $max-width-mobile) {
          gap: 50px;
        }
      }
    }
  }
  // Не успешная оплата
  .failed-payment {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .failed-payment__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 900px;
      padding: 1px;

      .failed-payment__image-box {
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          height: 100%;
          width: auto;
          position: relative;
          z-index: 1;
          mix-blend-mode: multiply;
          opacity: 0.7;
        }
        @media (max-width: $max-width-mobile) {
          height: 150px;
        }
      }
      .failed-payment__text-box {
        margin-top: 50px;
        margin-bottom: 50px;

        h2 {
          @include mv-weekend;
          font-size: $fz-xxxl2;
          color: $color-light-blue;
          text-align: center;

          @media (max-width: $max-width-tablet) {

          }
          @media (max-width: $max-width-mobile) {
            font-size: $fz-xxl2;
            font-weight: 400;
            max-width: 200px;
          }
        }
        .failed-payment__text-span {
          @media (max-width: $max-width-mobile) {
            display: none;
          }
        }
        @media (max-width: $max-width-mobile) {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 40px;
        }
      }
      .failed-payment__btn-box {
        display: flex;
        justify-content: center;
      }
    }
  }
}