@import "../../App/var";

.bottom-bar {
  display: none;

  @media (max-width: $max-width-tablet) {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    gap: 10px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 100%;
    height: 152px;
    // Первый вариант нижнего меню (высота 169пикс)
    // height: 169px;
    background: 
            url("../../../assets/images/v9-center-200.png") no-repeat center 6px / 200px 152px,
            url("../../../assets/images/v9-footer-down-all-width-after-photoshop.png") no-repeat center bottom / 100% 97px,
            // Первый вариант нижнего меню (высота 169пикс)
            // url("../../../assets/images/v9-center-200.png") no-repeat center 6px / 200px 169px,
            // url("../../../assets/images/v9-footer-down-all-width-after-photoshop.png") no-repeat center bottom / 100% 108px,
            transparent;
    padding: 25px 20px;

    .bottom-bar__left, .bottom-bar__right {
      display: flex;
      justify-content: space-around;
      flex-grow: 1;
      gap: 5px;
      
      .bottom-bar__image-box {
        display: flex;
        justify-content: center;
        height: 50px;
        width: 70px;
        // width: 100%;

        img {
          height: 100%;
          width: auto;
        }

        @media (max-width: $max-width-mobile) {
          height: 30px;
          width: 40px;
        }
      }
      .invisible-box {
        height: 50px;
        width: 70px;
        // background-color: #f3b1b1;

        @media (max-width: $max-width-mobile) {
          height: 30px;
          width: 40px;
        }
      }
    }
    .bottom-bar__centr {
      display: flex;
      justify-content: center;
      flex-grow: 0.5;
      width: 70px;
      height: 110px;
      position: relative;

      img {
        width: 66px;
        // width: 74px;
        height: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10%;
        // position: relative;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0.95;

        @media (max-width: $max-width-mobile) {
          width: 45px;
        }
    }
    }

  }
  // old version
  // @media (max-width: $max-width-mobile) {
  //   height: 150px;
  //   background: 
  //           url("/src/assets/images/bg-bottom-bar-mobile-center-150-130px.png") no-repeat center bottom / 150px 130px,
  //           url("/src/assets/images/bg-bottom-bar-mobile-390-90px.png") no-repeat center bottom / 100% 90px,
  //           transparent;
  //   padding: 25px 20px;
  // }
  @media (max-width: $max-width-mobile) {
    height: 113px; /* Новая высота контейнера (было 150px) */
    background: 
      url("/src/assets/images/bg-bottom-bar-mobile-center-150-130px.png") no-repeat center bottom / 150px 97px, /* Обрезаем высоту с 130px до 113px */
      url("/src/assets/images/bg-bottom-bar-mobile-390-90px.png") no-repeat center bottom / 100% 68px, /* Масштабируем высоту с 90px до 68px */
      transparent;
    padding: 20px 15px; /* Уменьшаем отступы пропорционально (опционально) */
  }
  
}