.gallery-profile-edit .gallery-profile-edit__top {
  display: flex;
  gap: 50px;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__avatar-box {
  display: flex;
  align-items: center;
  gap: 5px;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__avatar-box img {
  width: 83px;
  height: auto;
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__avatar-box img {
    width: 50px;
  }
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__avatar-box p {
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__avatar-box p {
    display: none;
  }
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__name-box {
  display: none;
  justify-content: center;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__name-box p {
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__name-box {
    display: flex;
  }
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box {
  display: flex;
  gap: 20px;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box .gallery-profile-edit__button-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box .gallery-profile-edit__button-item img {
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box .gallery-profile-edit__button-item img {
    width: 10px;
    height: 10px;
    margin-bottom: 2px;
  }
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box .gallery-profile-edit__button-item p {
  font-size: 16px;
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box .gallery-profile-edit__button-item p .gallery-profile-edit__button-text-garbage {
    display: none;
  }
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box .gallery-profile-edit__button-item p {
    font-size: 12px;
  }
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box .gallery-profile-edit__button-item:hover {
  cursor: pointer;
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__buttons-box {
    gap: 15px;
  }
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__input-box .gallery-profile-edit__text-new-name {
  display: none;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__input-box .gallery-profile-edit__text-new-name p {
  font-size: 16px;
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__input-box .gallery-profile-edit__text-new-name {
    display: flex;
  }
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__input-box .gallery-profile-edit__input-and-button {
  display: flex;
  gap: 20px;
}
.gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__input-box .gallery-profile-edit__input-and-button input {
  width: 370px;
  height: 50px;
  margin-top: 0;
}
@media (max-width: 1199px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right .gallery-profile-edit__input-box .gallery-profile-edit__input-and-button input {
    width: 300px;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top .gallery-profile-edit__top-right {
    gap: 7px;
  }
}
@media (max-width: 1199px) {
  .gallery-profile-edit .gallery-profile-edit__top {
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .gallery-profile-edit .gallery-profile-edit__top {
    gap: 7px;
  }
}/*# sourceMappingURL=GalleryProfileEdit.css.map */