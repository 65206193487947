@import "../App/var";

.global-modal {
  z-index: 100;
}
// Наполнение модального окна - Авторизация
.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 40;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 7vh;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .modal__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 28px 20px;
    border-radius: 20px;
    max-width: 500px;
    width: 100%;
    max-height: 90vh;
    // transform: scale(0.5);
    // transition: 1s;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, 
          rgba(238, 251, 255, 1) -5.22%, 
          rgba(255, 255, 255, 0.2) 15.36%, 
          rgba(255, 255, 255, 0.2) 79.35%, 
          rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    // Исправляем стили для iOS
    // @supports (-webkit-backdrop-filter: blur(0)) {
    //   .modal__content {
    //       background: 
    //           linear-gradient(rgba(238, 251, 255, 0.9), rgba(238, 251, 255, 0.9)) padding-box,
    //           linear-gradient(270.96deg, #EEFBFF -5.22%, rgba(255, 255, 255, 0.3) 15.36%, rgba(255, 255, 255, 0.3) 79.35%, #EEFBFF 106.06%) border-box;
    //   }
    // }
    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 30px 20px 20px;
    }
  }
  
  // .modal__content.active {
  //   transform: scale(1);
  // }

  @media (max-width: $max-width-tablet) {
    padding-top: 12vh;
  }
  @media (max-width: $max-width-mobile) {
    padding-top: 1vh;
  }
}

.modal.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}
// Наполнение модального окна - Меню(Бургер)
.burger {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: start;
  align-items: start;
  padding-top: 40px;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .burger__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 80px 20px;
    margin-left: 50px;
    border-radius: 20px;
    max-width: 420px;
    max-height: 99vh;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, 
          rgba(238, 251, 255, 1) -5.22%, 
          rgba(255, 255, 255, 0.2) 15.36%, 
          rgba(255, 255, 255, 0.2) 79.35%, 
          rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 20px 45px 20px;
    }
  }

  @media (max-width: $max-width-mobile) {
    padding-top: 30px;
  }
}
.burger.active {
  opacity: 1;
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
}
// Наполнение модального окна - Подтверждение выхода
.confirm {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: flex-end;
  align-items: start;
  padding-top: 35px;
  padding-right: 40px;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .confirm__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 60px 30px;
    border-radius: 20px;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, rgba(238, 251, 255, 1) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 30px 20px 20px;
    }
  }
  
  @media (max-width: $max-width-mobile) {
    justify-content: center;
    padding-top: 15vh;
    padding-right: 0;
  }
}

.confirm.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}
// Наполнение модального окна - Информирование (Пример: Ваша заявка успешно отправлена)
.success {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .success__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 30px;
    border-radius: 20px;
    max-width: 500px;
    width: 100%;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, rgba(238, 251, 255, 1) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 50px 10px;
    }
  }

  @media (max-width: $max-width-tablet) {

  }
  @media (max-width: $max-width-mobile) {

  }
}

.success.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}
// Наполнение модального окна - cooperation (Сотрудничество)
.cooperation_modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .cooperation_modal__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 28px 20px;
    border-radius: 20px;
    max-width: 500px;
    width: 100%;
    max-height: 90vh;
    // transform: scale(0.5);
    // transition: 1s;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, 
          rgba(238, 251, 255, 1) -5.22%, 
          rgba(255, 255, 255, 0.2) 15.36%, 
          rgba(255, 255, 255, 0.2) 79.35%, 
          rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 30px 20px 20px;
    }
  }
  
  // .cooperation_modal__content.active {
  //   transform: scale(1);
  // }

  @media (max-width: $max-width-tablet) {
    padding-top: 12vh;
  }
  @media (max-width: $max-width-mobile) {
    padding-top: 1vh;
  }
}

.cooperation_modal.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

// Наполнение модального окна - Редактирование Лого/Имени/Удалить (фото для поиска в галерее)
.galleryprofile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 39;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .galleryprofile__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px 35px 0px 0px;
    border-radius: 50px 20px 20px 50px;
    max-height: 90vh;
    margin-top: 165px;
    margin-left: 148px;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, 
          rgba(238, 251, 255, 1) -5.22%, 
          rgba(255, 255, 255, 0.2) 15.36%, 
          rgba(255, 255, 255, 0.2) 79.35%, 
          rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;
    overflow: hidden;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;

      @media (max-width: $max-width-mobile) {
        top: 12px;
        right: 12px;
        width: 13px;
        height: auto;

      }
    }
    @media (max-width: $max-width-tablet) {
      margin-top: 102px;
      margin-left: 20px;
      margin-right: 5px;
    }
    @media (max-width: $max-width-mobile) {
      max-width: 98vw;
      margin-top: 124px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 0px 30px 0px 0px;
      border-radius: 50px 50px 50px 50px;
    }
  }
  
  // .galleryprofile__content.active {
  //   transform: scale(1);
  // }

  @media (max-width: $max-width-tablet) {
    padding-top: 12vh;
  }
  @media (max-width: $max-width-mobile) {
    padding-top: 1vh;
  }
}

.galleryprofile.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

// Модаьный контент - Подтверждение удаления фотографии
.confirm_cart_photo_delete {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .confirm_cart_photo_delete__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 30px 30px;
    border-radius: 20px;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, rgba(238, 251, 255, 1) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      min-width: 370px;
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 30px 20px 20px;
    }
  }
  
  @media (max-width: $max-width-mobile) {
    justify-content: center;
    padding-top: 15vh;
    padding-right: 0;
  }
}

.confirm_cart_photo_delete.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

// Модальный контент - Фото карусель - (Универсальный для части client/ )
.photo_carousel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 35px;
  // padding-right: 40px;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .photo_carousel__content {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    // padding: 50px 60px 30px;
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, rgba(238, 251, 255, 1) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      // top: 50px;
      // right: 50px;
      top: 2px;
      right: 2px;
      @media (max-width: $max-width-mobile) {
        top: 15px;
        right: 15px;
      }
    }
  }
}

.photo_carousel.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

// Модальный контент - Подтверждение успешной регистрации фотографа
.coop_reg_success {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .coop_reg_success__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 28px 20px;
    border-radius: 20px;
    max-width: 500px;
    width: 100%;
    max-height: 90vh;
    // transform: scale(0.5);
    // transition: 1s;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, 
          rgba(238, 251, 255, 1) -5.22%, 
          rgba(255, 255, 255, 0.2) 15.36%, 
          rgba(255, 255, 255, 0.2) 79.35%, 
          rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 30px 20px 20px;
    }
  }
  
  // .cooperation_modal__content.active {
  //   transform: scale(1);
  // }

  @media (max-width: $max-width-tablet) {
    padding-top: 12vh;
  }
  @media (max-width: $max-width-mobile) {
    padding-top: 1vh;
  }
}

.coop_reg_success.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.error_payment_modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 1s;
  opacity: 0;
  pointer-events: none;

  .error_payment_modal__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 28px 20px;
    border-radius: 20px;
    max-width: 500px;
    width: 100%;
    max-height: 90vh;
    // transform: scale(0.5);
    // transition: 1s;
    border: 2px solid transparent; 
    background: 
        linear-gradient(rgba(238, 251, 255, 0.3), rgba(238, 251, 255, 0.3)) padding-box,
        linear-gradient(270.96deg, 
          rgba(238, 251, 255, 1) -5.22%, 
          rgba(255, 255, 255, 0.2) 15.36%, 
          rgba(255, 255, 255, 0.2) 79.35%, 
          rgba(238, 251, 255, 1) 106.06%) border-box;
    backdrop-filter: blur(5px);
    box-shadow: 5px 10px 10px 0px #0000000D;

    .close-btn-box {
      display: flex;
      justify-content: end;
      position: absolute;
      z-index: 41;
      top: 7px;
      right: 7px;
    }

    @media (max-width: $max-width-mobile) {
      max-width: 450px;
      margin-left: 5px;
      margin-right: 5px;
      padding: 30px 20px 20px;
    }
  }
  
  // .cooperation_modal__content.active {
  //   transform: scale(1);
  // }

  @media (max-width: $max-width-tablet) {
    padding-top: 12vh;
  }
  @media (max-width: $max-width-mobile) {
    padding-top: 1vh;
  }
}

.error_payment_modal.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}
