@import '../../../App/App.scss';

.coop-reg-success-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
  max-height: 480px;

  .coop-reg-success__leave-request {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 320px;

    .coop-reg-success-wrapper__title-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
  
      .coop-reg-success-wrapper__title {
        font-size: $fz-m2;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    
        @media (max-width: $max-width-mobile) {
          font-size: $fz-m;
        }
      }
    }
    .coop-reg-success-wrapper__input-name-box,
    .coop-reg-success-wrapper__input-phone-box {
      display: flex;
      flex-direction: column;
      // background-color: #bdf5ac;
      min-height: 120px;
    }
    .coop-reg-success-wrapper__btn-box {
      min-height: 48px;
      display: flex;
      justify-content: center;
    }
  }
  .coop-reg-success-wrapper__info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;

    .coop-reg-success-wrapper__title-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
  
      .coop-reg-success-wrapper__title {
        font-size: $fz-m2;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    
        @media (max-width: $max-width-mobile) {
          font-size: $fz-m;
        }
      }
    }
    p {
      text-align: center;
    }
  }
}