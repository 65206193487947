@import "../../App/var";

.home {
  display: flex;
  height: 100%;
  width: 100%;
  // Правильный вариант фона (оттенки в цвете)
  background: url("../../../assets/images/image-noize.png"), 
    linear-gradient(90deg, 
      rgba(151, 199, 204, 1) 0%, 
      rgba(192, 232, 237, 1) 40%, 
      rgba(192, 232, 237, 1) 60%, 
      rgba(151, 199, 204, 1) 100%);
  background-size: 50px 50px, auto;
  background-repeat: round, no-repeat;
}

// Основной раздел с контентом
.main {
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  padding-top: 5px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 350px;
  transition-property: padding-left;
  transition-duration: 1s;
  transition-timing-function: ease;

  @media (max-width: $max-width-tablet) {
    padding-top: 140px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 180px;
  }
  @media (max-width: $max-width-mobile) {
    padding-top: 75px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 105px;
  }
}
.main.main-pedding-left-small {
  padding-left: 150px;
}

