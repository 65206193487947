@import "../App/var";
@import "../App/App.scss";

.image {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;

  .image__btn-photo {
    position: relative;

    .image__btn-photo-box {
      width: 250px;
      height: 250px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Масштабируем изображение, обрезая его по краям, чтобы заполнить контейнер */
        object-position: center; /* Центрируем изображение в контейнере */
      }
      @media (max-width: $max-width-mobile) {
        width: 110px;
        height: 110px;
      }
    }
    .image__checkbox-select-photo-box {

      .image__checkbox-select-photo {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        border-radius: 10px;

        @media (max-width: $max-width-mobile) {
          top: 5px;
          right: 5px;
        }
      }
      .image__checkbox-favourites-photo {
        position: absolute;
        z-index: 2;
        bottom: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        border-radius: 10px;

        @media (max-width: $max-width-mobile) {
          bottom: 5px;
          right: 5px;
        }
      }
      .image__checkbox-download-photo {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        border-radius: 10px;

        @media (max-width: $max-width-mobile) {
          bottom: 5px;
          right: 5px;
        }
      }
      .custom-checkbox {
        display: flex;
        align-items: center;
        gap: 5px;

        // Скрываем стандартный чек-бокс
        .custom-checkbox-input {
          display: none;
        }
        // Показываем картинку - кастомного чек-бокса
        .custom-checkbox-icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-image: url('../../assets/images/image-checkbox-uncheck.png');
          background-size: cover;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 25px;
          opacity: 0.5;
        }
        /* Меняем изображение при активации чекбокса */
        .custom-checkbox-input:checked + .custom-checkbox-icon {
          background-image: url('../../assets/images/image-checkbox-checked.png');
          opacity: 1;
        }
      }
      /* Кастомный чекбокс - Избранное */
      .custom-checkbox-favourites {
        display: flex;
        align-items: center;
        gap: 5px;

        .custom-checkbox-input-favourites {
          display: none;
        }
        /* Стили для кастомной галочки */
        .custom-checkbox-icon-favourites {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-image: url('../../assets/images/image-checkbox-uncheck-favourite.svg');
          background-size: cover;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 25px;
          opacity: 0.5;
        }
        /* Меняем изображение при активации чекбокса */
        .custom-checkbox-input-favourites:checked + .custom-checkbox-icon-favourites {
          background-image: url('../../assets/images/image-checkbox-favourites-v2.svg');
          opacity: 1;
        }
      }
      // Кастомный чекбокс - Скачать
      .custom-checkbox-download {
        display: flex;
        align-items: center;
        gap: 5px;

        .custom-checkbox-input-download {
          display: none;
        }
        /* Стили для кастомной галочки */
        .custom-checkbox-icon-download {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-image: url('../../assets/images/image-checkbox-uncheck.png');
          background-size: cover;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 25px;
        }
        /* Меняем изображение при активации чекбокса */
        .custom-checkbox-input-download:checked + .custom-checkbox-icon-download {
          background-image: url('../../assets/images/image-checkbox-download.svg');
        }
      }
    }
  }
  @media (max-width: $max-width-mobile) {
    width: 110px;
    height: 110px;
  }
}