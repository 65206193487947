@import '../../../App/var';
@import '../../../App/App.scss';

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .contacts__title {

    h2 {
      @include mv-weekend;
      font-size: $fz-xxxl2;
      line-height: 64px;
      color: $color-light-blue;
      text-align: center;
      margin-bottom: 20px;

      @media (max-width: $max-width-tablet) {
        margin-bottom: 0px;
      }
      @media (max-width: $max-width-mobile) {
        @include golos;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        color: $color-dark-blue-gray;
        text-transform: uppercase;
        margin-bottom: 0px;
      }
    }
    @media(max-width: $max-width-tablet) {
      margin-top: 30px;
    }
  }
  .contacts__social-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 5vh;

    .contacts__social-item {
      height: 60px;
      width: 60px;
      
      img {
        width: 100%;
        height: 100%;
      }
      @media (max-width: $max-width-mobile) {
        width: 40px;
        height: 40px;
      }
    }
    @media (max-width: $max-width-tablet) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @media (max-width: $max-width-mobile) {
      flex-wrap: wrap;
      max-width: 320px;
    }
  }
  .contacts__tech-support-title {

    h3 {
      @include mv-weekend;
      font-size: $fz-xxl2;
      line-height: 1;
      color: $color-light-blue;
      text-align: center;
      margin-bottom: 20px;

      @media (max-width: $max-width-tablet) {
        margin-bottom: 0px;
      }
      @media (max-width: $max-width-mobile) {
        @include golos;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        color: $color-dark-blue-gray;
        text-transform: uppercase;
        margin-bottom: 0px;
      }
    }
    @media(max-width: $max-width-tablet) {
      margin-top: 30px;
    }
  }
  .contacts__tech-support-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 4vh;

    .contacts__tech-support-item {
      height: 60px;
      width: 60px;
      
      img {
        width: 100%;
        height: 100%;
      }
      @media (max-width: $max-width-mobile) {
        width: 40px;
        height: 40px;
      }
    }
    @media (max-width: $max-width-tablet) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @media (max-width: $max-width-mobile) {
      flex-wrap: wrap;
      max-width: 320px;
    }
  }
  .contacts__phone {
    margin-bottom: 5vh;

    .contacts__phone-content {
      display: flex;
      gap: 10px;

      .contacts__phone-image-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 42px;

        img {
          width: 100%;
          height: auto;
        }
        @media (max-width: $max-width-mobile) {
          width: 25px;
          height: 35px;
        }
      }
      .contacts__phone-number-box {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: $fz-xxl;

          @media (max-width: $max-width-mobile) {
            font-size: $fz-m;
          }
        }
      }
    }
    @media (max-width: $max-width-tablet) {
      margin-bottom: 20px;
    }
    @media (max-width: $max-width-mobile) {
      margin-bottom: 20px;
    }
  }
  .contacts__text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 700px;

    p {
      text-align: center;
    }
  }
}