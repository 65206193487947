.leftbar {
  min-height: 640px;
  height: 100%;
  width: 330px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  padding: 90px 35px 90px 22px;
  background: url("../../../assets/images/desktop-menu-small-center-200.png") no-repeat right center/130px 200px, url("../../../assets/images/desktop-menu-small-top-200.png") no-repeat right top/130px 200px, url("../../../assets/images/desktop-menu-small-main-1080.png") no-repeat calc(100% - 29px) center/101px 100%, linear-gradient(to right, #d7f4f8, #d7f4f8 calc(100% - 50px), transparent calc(100% - 50px));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  transition-property: width;
  transition-duration: 1s;
  transition-timing-function: ease;
}
.leftbar .menu__logo-box {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-85%);
  z-index: 51;
  height: 47px;
  width: auto;
}
.leftbar .menu__logo-box img {
  height: 100%;
}
.leftbar .menu__arrow-box {
  position: absolute;
  top: 36px;
  right: 4px;
  z-index: 51;
  padding: 25px 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftbar .menu__arrow-box img {
  transform: rotate(0deg);
  width: 7px;
  height: 10px;
}
.leftbar .menu__arrow-box .arrow-to-left {
  transform: rotate(180deg);
}
.leftbar .menu__hint {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: -180px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 52;
  mix-blend-mode: multiply;
}
.leftbar .menu__hint .menu__hint__image-box {
  display: flex;
  width: 26px;
  height: auto;
}
.leftbar .menu__hint .menu__hint__image-box img {
  position: relative;
  z-index: 53;
  mix-blend-mode: multiply;
  opacity: 0.95;
  width: 100%;
}
.leftbar .menu__hint .menu__hint__text-box {
  display: flex;
  justify-content: start;
  width: 137px;
}
.leftbar .menu__hint .menu__hint__text-box p {
  font-size: 16px;
}
.leftbar .menu__top, .leftbar .menu__down {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
  flex-grow: 1;
  width: 100%;
}
.leftbar .menu__top .menu__top__item, .leftbar .menu__top .menu__down__item, .leftbar .menu__down .menu__top__item, .leftbar .menu__down .menu__down__item {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
}
.leftbar .menu__top .menu__top__item .menu__item__image-box, .leftbar .menu__top .menu__down__item .menu__item__image-box, .leftbar .menu__down .menu__top__item .menu__item__image-box, .leftbar .menu__down .menu__down__item .menu__item__image-box {
  width: 45px;
  min-width: 45px;
  height: 34px;
  min-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftbar .menu__top .menu__top__item .menu__item__image-box img, .leftbar .menu__top .menu__down__item .menu__item__image-box img, .leftbar .menu__down .menu__top__item .menu__item__image-box img, .leftbar .menu__down .menu__down__item .menu__item__image-box img {
  height: 100%;
  width: auto;
}
.leftbar .menu__top .menu__top__item .menu__item__text-box, .leftbar .menu__top .menu__down__item .menu__item__text-box, .leftbar .menu__down .menu__top__item .menu__item__text-box, .leftbar .menu__down .menu__down__item .menu__item__text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
.leftbar .menu__top .menu__top__item .menu__item__text-box .menu__item__text, .leftbar .menu__top .menu__down__item .menu__item__text-box .menu__item__text, .leftbar .menu__down .menu__top__item .menu__item__text-box .menu__item__text, .leftbar .menu__down .menu__down__item .menu__item__text-box .menu__item__text {
  font-size: 20px;
  opacity: 1;
  transition: opacity 1s ease-in, visibility 1s ease-in, font-size 1s ease-in;
}
.leftbar .menu__top .menu__top__item .menu__item__text-box .menu__item__text.hide-text, .leftbar .menu__top .menu__down__item .menu__item__text-box .menu__item__text.hide-text, .leftbar .menu__down .menu__top__item .menu__item__text-box .menu__item__text.hide-text, .leftbar .menu__down .menu__down__item .menu__item__text-box .menu__item__text.hide-text {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in, visibility 1s ease-in;
  font-size: 0px;
}
.leftbar .menu__center {
  display: flex;
  width: 100%;
}
.leftbar .menu__center .menu__center__item {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  gap: 6px;
}
.leftbar .menu__center .menu__center__item .menu__center-item__image-box {
  width: 63px;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  background: none;
}
.leftbar .menu__center .menu__center__item .menu__center-item__image-box img {
  height: auto;
  position: relative;
  z-index: 1;
  mix-blend-mode: multiply;
  opacity: 0.8;
}
.leftbar .menu__center .menu__center__item .menu__item__text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.leftbar .menu__center .menu__center__item .menu__item__text-box .menu__item__text {
  font-size: 20px;
  opacity: 1;
  transition: opacity 1s ease-in, visibility 1s ease-in, font-size 1s ease-in;
}
.leftbar .menu__center .menu__center__item .menu__item__text-box .menu__item__text.hide-text {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in, visibility 1s ease-in;
  font-size: 0px;
}
@media (max-width: 1199px) {
  .leftbar {
    display: none;
  }
}

.leftbar-collapse {
  width: 130px;
}/*# sourceMappingURL=LeftBar.css.map */