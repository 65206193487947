@import "../../../../App/var";
@import "../../../../App/App.scss";

.cart-photo-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;

  .cart-photo-item__top {
    display: flex;
    justify-content: space-between;

    .cart-photo-item__top-left {
      display: flex;
      gap: 20px;

      // old version
      // .cart-photo-item__photo-box {
      //   width: 150px;
      //   min-width: 150px;
      //   height: 150px;
      //   border-radius: 10px;
      //   overflow: hidden;
      //   cursor: pointer;

      //   img {
      //     width: 100%;
      //     height: auto;
      //   }
      //   @media (max-width: $max-width-mobile) {
      //     width: 130px;
      //     min-width: 130px;
      //     height: 130px;
      //   }
      // }
      .cart-photo-item__photo-box {
        width: 150px;
        min-width: 150px;
        height: 150px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          display: block;
        }

        @media (max-width: $max-width-mobile) {
          width: 130px;
          min-width: 130px;
          height: 130px;
        }
      }
      .cart-photo-item__description-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cart-photo-item__description-top {
          p {
            font-size: $fz-l;

            @media (max-width: $max-width-mobile) {
              font-size: $fz-xs;
            }
          }
          .cart-photo-item__date {
            font-weight: 700;
          }
          .cart-photo-item__period-of-storage {
            font-size: $fz-s;
            color: $color-dark-blue-gray05;
            padding-top: 10px;

            @media (max-width: $max-width-mobile) {
              font-size: $fz-xxs2;
            }
          }
        }
        .cart-photo-item__description-bottom {

          p {
            font-size: $fz-l;
            font-weight: 500;

            @media (max-width: $max-width-mobile) {
              font-size: $fz-s;
            }
          }
        }
      }
    }
    .cart-photo-item__top-right {
      width: 20px;
      height: 100%;
      display: flex;
      align-items: flex-end;

      img {
        width: 100%;
        height: auto;
        cursor: pointer;
      }
    }
  }
  .cart-photo-item__line {
    border-top: 2px dashed $color-steel-blue;
    width: 100%;
    margin-top: 20px;
  }
}