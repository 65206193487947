@import "./var.scss";
@import "./fonts.scss";

// Mixins
@mixin golos {
  font-family: 'Golos-UI', sans-serif;
}
@mixin golos-vf {
  font-family: 'Golos-UI-VF', sans-serif;
}
@mixin mv-weekend {
  font-family: 'MV-WEEKEND', sans-serif;
}

html {
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}
  
body {
  font-weight: 400;
  color: $color-dark-blue-gray;
  height: 100%;
  width: 100vw;
}
// global wrapper
.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
// .modal-open-no-scroll {
//   overflow: hidden;
// }
  
*, *::before, *::after {
  box-sizing: inherit;
}

img {
  display: block;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  padding: 0;
  margin: 0;
}
h2, h3, h4, h5, h6, p, ul, li {
  @include golos;
}

p {
  font-size: 20px;
  line-height: 1.2;
  color: $color-dark-blue-gray;
}
  
a {
  text-decoration: none;
}
button {
  background-color: transparent;
  border-style: none;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}
.hidden {
  display: none !important;
}
// Добавив этот класс, элемент получит прозрачность в указанной степени
.opacity30 {
  opacity: 0.3;
}
.opacity30:hover {
  opacity: 0.7;
}
.opacity30:active {
  opacity: 1;
}
// У последующих принцип работы отличается от opacity30
.opacity50 {
  opacity: 0.5;
}
.opacity50:hover {
  opacity: 0.65;
}
.opacity60 {
  opacity: 0.6;
}
.opacity60:hover {
  opacity: 1;
}
.opacity70 {
  opacity: 0.7;
}
.opacity70:hover {
  opacity: 1;
}
.container-m {
  max-width: 950px;
  width: 100%;
}

// Общие стили

// Кнопки
// Кнопка для модального окна
.btn-modal {
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
  
  p {
    @include golos;
    font-weight: 500;
    font-style: normal;
    font-size: $fz-m2;
    line-height: 22px;
    text-transform: uppercase;
    color: $color-light-blue;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-m;
      line-height: 1.2;
    }
  }
}
/* Исправляем возможные расхождения через медиазапросы для iOS */
/* Специальные стили для iOS Safari */
// @supports (-webkit-touch-callout: none) {
//   .btn-modal {
//       background-image: none;
//       background-color: #EEFBFF;
//       box-shadow: inset 0 100vw rgba(116, 140, 149, 1), 0 0 3px rgba(255, 255, 255, 0.2);
//   }
// }

// Кнопка стандартная светлая
.btn-standard {
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
  
  p {
    @include golos;
    font-weight: 500;
    font-style: normal;
    font-size: $fz-m2;
    line-height: 22px;
    text-transform: uppercase;
    color: $color-dark-blue-gray;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-m;
      line-height: 1.2;
    }
  }
}

// Кнопка стандартная: вариант второй
.button-v2 {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;

  p {
    color: $color-light-blue;
    font-size: $fz-xxs;
    line-height: 1;
  }
}
// Состояние "не активно"
.button-v2.not-active {
  background-color: $color-cool-stone05;
  pointer-events: none;
}
// Состояние "активно"
.button-v2.active {
  background-color: $color-cool-stone;
}
// Состояние при наведении
.button-v2.active:hover {
  border-color: $color-dark-blue-gray;
}
// Состояние при клике
.button-v2.active:active {
  background-color: $color-dark-blue-gray;
  border-color: $color-dark-blue-gray;
}

/* Микс стиль - Кнопка Из тёмнго фона в светлый */
.btn-dark-to-light {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, #EEFBFF -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, #EEFBFF 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw $color-btn-modal, 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  p {
    color: $color-light-blue;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transition: color 0.5s ease;
  }
}
.btn-dark-to-light * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-dark-to-light:hover {
  opacity: 0.8;
}
.btn-dark-to-light:active {
  background-image: linear-gradient(270.96deg, #EEFBFF -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, #EEFBFF 106.06%);
  background-origin: border-box;
  box-shadow: inset 0 100vw $color-btn-modal-active;

  p {
    color: $color-light-blue;
  }
}

// Актуальный стиль кнопки для client/ and manager/
// Микс стиль - Кнопка Тёмный фон
// Если нужен стиль - Неактивная кнопка: добавляем элементу класс btn-disabled
.btn-dark {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(
    270.96deg, 
    rgba(238, 251, 255, 1) -5.22%, 
    rgba(255, 255, 255, 0.2) 15.36%, 
    rgba(255, 255, 255, 0.2) 79.35%, 
    rgba(238, 251, 255, 1) 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(116, 140, 149, 1), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 3px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  transition: background-color 1s;
  display: inline-block;
  flex-grow: 0;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  p {
    @include golos;
    font-weight: 500;
    font-style: normal;
    font-size: $fz-m2;
    line-height: 22px;
    text-transform: uppercase;
    color: $color-light-blue;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transition: color 0.5s ease;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-m;
      line-height: 1.2;
    }
  }
}
.btn-dark * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-dark:hover {
  // Оформление бордера кнопки
  background-image: linear-gradient($color-dark-gray, $color-dark-gray);
  // Оформление фона элемента
  box-shadow: inset 0 100vw $color-cool-stone, 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
}
.btn-dark:active {
  background-image: linear-gradient($color-dark-blue-gray, $color-dark-blue-gray);
  box-shadow: inset 0 100vw $color-dark-blue-gray;
}
// Стили для отключенной (неактивной кнопки), у нее одновременно дожны быть два класса
.btn-dark.btn-disabled,
.btn-dark.btn-disabled:hover,
.btn-dark.btn-disabled:active {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(
    270.96deg, 
    rgba(238, 251, 255, 1) -5.22%, 
    rgba(255, 255, 255, 0.2) 15.36%, 
    rgba(255, 255, 255, 0.2) 79.35%, 
    rgba(238, 251, 255, 1) 106.06%);
  /* Цвет внутренней часть кнопки */
  box-shadow: inset 0 100vw rgba(116, 140, 149, 1), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  opacity: 0.5;
}

// Старые стили: из светлого в темный и из темного в светлый
 /* Микс стиль - Кнопка Из светлого фона в тёмный */
 .btn-light-to-dark {
  background-image: linear-gradient(270.96deg, 
    rgba(238, 251, 255, 1) -5.22%, 
    rgba(255, 255, 255, 0.2) 15.36%, 
    rgba(255, 255, 255, 0.2) 79.35%, 
    rgba(238, 251, 255, 1) 106.06%
  );
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(215, 244, 251, 0.6);
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-light-to-dark * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-light-to-dark:hover {
  box-shadow: inset 0 100vw rgba(194, 220, 226, 0.6);
}
.btn-light-to-dark p {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-light-to-dark:active {
  background-image: linear-gradient(270.96deg, 
  rgba(238, 251, 255, 1) -5.22%, 
  rgba(255, 255, 255, 0.2) 15.36%, 
  rgba(255, 255, 255, 0.2) 79.35%, 
  rgba(238, 251, 255, 1) 106.06%
  );
  background-origin: border-box;
  box-shadow: inset 0 100vw rgb(116, 140, 149);
}
.btn-light-to-dark:active p {
  color: rgba(238, 251, 255, 1);
}

// Кнопка - стиль - не активная
.btn-disabled {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, 
  rgba(238, 251, 255, 1) -5.22%, 
  rgba(255, 255, 255, 0.2) 15.36%, 
  rgba(255, 255, 255, 0.2) 79.35%, 
  rgba(238, 251, 255, 1) 106.06%
  );
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw $color-btn-modal-disabled, 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
// Добавляем этот класс к элементу который неактивен (к ссылкам на соц сети и тех.поддержку)
.link-disabled {
  cursor: default !important;
  opacity: 0.5;
}

.btn-padding-10-20 {
  padding: 10px 20px;
}
.btn-padding-10-40 {
  padding: 10px 40px;
}

// Поля ввода данных модальных окон
.modal-input-box,
.input__box {
  
  .modal-label {
    @include golos;
    font-size: $fz-s;
    line-height: 21px;
    color: $color-dark-blue-gray;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-xs;
      line-height: 1.2;
    }
  }
  .modal-input {
    margin-top: 5px;
    padding: 18px 20px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px solid transparent;
    background-color: $color-white;
    @include golos;
    font-weight: 400;
    font-size: $fz-m2;
    line-height: 24px;
    color: $color-stormy-sea;
    position: relative;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-xs;
      line-height: 1.2;
    }
  }
  // ^ Для подсветки некорректного ввода, добавить класс modal-input-wrong
  .modal-input-wrong {
    background-color: $color-wrong;
  }

  .modal-input:focus {
    border: 1px solid rgba(113, 137, 143, 0.5);
    outline: none;
  }
  .modal-input::placeholder {
    @include golos;
    font-weight: 400;
    font-size: $fz-m2;
    line-height: 24px;
    color: $color-misty-sea;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-xs;
      line-height: 1.2;
    }
  }
  .hint-box {

    p {
      @include golos;
      font-size: $fz-s;
      color: $color-dark-blue-gray;

      @media (max-width: $max-width-mobile) {
      font-size: $fz-xs;
      line-height: 1.2;
      }
    }
  }
}
// Класс с позитион релатив чтобы разместить кнопку глаз в углу поля для ввода пароля
.password-box {
  position: relative;
}
.show-dont-show-password {
  position: absolute;
  z-index: 4;
  bottom: 18px;
  right: 22px;
  border-style: none;
  background: transparent;
  padding: 0;
  // width: 22px;
  // height: 16px;
  height: 24px;
  width: auto;

  img {
    height: 100%;
    width: auto;
  }
}

.wrong-data-box {
  display: flex;
  justify-content: center;
  padding: 1px;
  margin-top: 3px;

  p {
    font-size: $fz-xs;
    color: $color-warning;
  }
}

// Стилизация элемента - Как модальное окно
.like-modal-blur {
  border-radius: 20px;
  border: 3px solid transparent; 
  background: 
    linear-gradient(
      rgba(238, 251, 255, 0.7), 
      rgba(238, 251, 255, 0.7)) padding-box,
    linear-gradient(
      270.96deg, 
      rgba(238, 251, 255, 1) -5.22%, 
      rgba(255, 255, 255, 0.2) 15.36%, 
      rgba(255, 255, 255, 0.2) 79.35%, 
      rgba(238, 251, 255, 1) 106.06%) border-box;
  backdrop-filter: blur(5px);
}

// Изменение стилей для Чата поддержки
// Первый вариант 02.12.2024 и 07.12.2024
// .dg__social-widget {
//   z-index: 20 !important;
//   bottom: 25px !important;
//   right: 40px !important;

//   @media (max-width: $max-width-tablet) {
//     right: 8vw !important;
//   }
// }
// .dg__social-widget__simple-popup {
//   height: 220px !important;
//   width: 64px !important;
//   display: flex !important;
//   flex-direction: column !important;
//   padding-bottom: 0px !important;

//   .dg__social-widget__buttons {
//     height: 100% !important;
//     flex-direction: column !important;
//     gap: 15px !important;

//     .dg__social-widget__popup__copyright {
//       display: none !important;
//     }
//     .dg__social-widget__button {
//       @media (max-width: $max-width-mobile) {
//         width: 40px !important;
//         height: 40px !important;
//       }
//     }
//     .dg__social-widget__button:not(:last-child) {
//       margin-right: 0px !important;
//     }
//   }
//   .dg__social-widget__simple-popup__close {
//     font-size: 30px;
//     padding: 10px;

//     @media (max-width: $max-width-mobile) {
//       padding: 0px  !important;
//       padding-bottom: 7px !important;
//       width: 40px  !important;
//       height: 30px  !important;
//     }
//   }
//   @media (max-width: $max-width-mobile) {
//     width: 40px !important;
//   }
// }
// .dg__social-widget__root-button {
//   border-style: none !important;
//   border-radius: 0px !important;
//   width: 57px !important;
//   height: 50px !important;
//   background-size: contain !important;
//   background-position: center !important;
//   background-repeat: no-repeat !important;

//   @media (max-width: $max-width-mobile) {
//     width: 40px !important;
//     height: 30px !important;
//   }
// }

// Второй вариант 28.03.2025
// .fixed {
//   bottom: 100px !important;
// }