@import "../App/var";

.header {
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 80px;
  padding: 25px 30px 10px 30px;
  
  .header__left {
    display: none;
    

    .header__btn-menu {
      width: 64px;
      height: 64px;
      border-radius: 100%;
      border-style: none;
      box-shadow: $box-shadow-lite;

      img {
        width: 100%;
        height: auto;
        
      }
      @media (max-width: $max-width-mobile) {
        width: 36px;
        height: 36px;
      }
    }

    @media (max-width: $max-width-tablet) {
      display: flex;
      min-width: 160px;
      gap: 30px;
    }
    @media (max-width: $max-width-mobile) {
      display: flex;
      min-width: 100px;
      gap: 20px;
    }
    
  }
  .header__center {
    display: none;
    max-width: 125px;
    height: auto;

    img {
      width: 100%;
    }

    @media (max-width: $max-width-tablet) {
      display: flex;
    }
    @media (max-width: $max-width-mobile) {
      max-width: 58px;
    }
  }
  .header__right {
    display: flex;
    justify-content: flex-end;
    gap: 30px;

    // Планшет и Мобильные
    .header__btn-login-box,
    .header__btn-basket-box,
    .header__btn-logout-box {
      display: none;
      width: 64px;
      height: 64px;

      .header__btn-login,
      .header__btn-basket,
      .header__btn-logout {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border-style: none;
        box-shadow: $box-shadow-lite;
        position: relative;
  
        img {
          width: 100%;
          height: auto;
        }
        .cart-badge {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-pink;
          padding: 1px;
          border-style: none;
          border-radius: 100%;
          min-width: 25px;
          min-height: 25px;
          position: absolute;
          z-index: 10;
          top: 5px;
          right: 0;

          p {
            font-size: $fz-xxs;
            line-height: 1;
            @media (max-width: $max-width-mobile) {
              font-size: $fz-xxs2;
            }
          }
          @media (max-width: $max-width-mobile) {
            min-width: 23px;
            min-height: 23px;
            top: -5px;
            right: -5px;
          }
        }
      }
      @media (max-width: $max-width-tablet) {
        display: flex;
      }
      @media (max-width: $max-width-mobile) {
        display: flex;
        width: 36px;
        height: 36px;
      }
    }
    // ПК
    .header__btn-login-desktop,
    .header__btn-basket-desktop,
    .header__btn-logout-desktop {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      .header__btn-login-desktop__img-box,
      .header__btn-basket-desktop__img-box,
      .header__btn-logout-desktop__img-box {
        height: 29px;
        width: auto;
        position: relative;
  
        .img {
          height: 100%;
        }
        .cart-badge {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-pink;
          padding: 1px;
          border-style: none;
          border-radius: 100%;
          min-width: 25px;
          min-height: 25px;
          position: absolute;
          z-index: 10;
          top: -10px;
          right: -10px;

          p {
            font-size: $fz-xxs2;
            line-height: 1;
          }
        }
      }
      .header__btn-login-desktop__text-box,
      .header__btn-basket-desktop__text-box,
      .header__btn-logout-desktop__text-box {
        display: flex;
        align-items: center;
        
        p {
          text-transform: uppercase;
        }
      }
      @media (max-width: 1199px) {
        display: none;
      }
    }
    @media (max-width: $max-width-tablet) {
      min-width: 160px;
      gap: 30px;
    }
    @media (max-width: $max-width-mobile) {
      min-width: 100px;
      gap: 20px;
    }
  }
  .header__btn-back {
    width: 64px;
    height: 64px;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -56px;
    left: 30px;
    cursor: pointer;

    img {
      width: 40px;
      height: auto;

      @media (max-width: $max-width-mobile) {
        width: 21px;
      }
    }
    @media (max-width: $max-width-tablet) {
      display: flex;
      bottom: -76px;
    }
    @media (max-width: $max-width-mobile) {
      width: 36px;
      height: 36px;
      bottom: -36px;
      left: 15px;
    }
  }
    
  @media (max-width: $max-width-tablet) {
    justify-content: space-between;
    position: fixed;
    z-index: 50;
    top: 0px;
    left: 0px;
    width: 100%;
    background-size: 50px 50px, auto;
    background-repeat: round, no-repeat;
    background: 
      url("../../assets/images/image-noize.png"), 
      linear-gradient(90deg, rgb(151, 199, 204) 0%, 
      rgb(192, 232, 237) 40%, 
      rgb(192, 232, 237) 60%, 
      rgb(151, 199, 204) 100%);
    box-shadow: 5px 10px 10px 0px #0000000D;
  }
  @media (max-width: $max-width-mobile) {
    padding: 10px 15px 10px 15px;
    min-height: 70px;
  }
}