@import '../../../App/var';
@import '../../../App/App.scss';

.findself {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .findself__scan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .findself__title-box {
      order: 1;

      h2 {
        @include mv-weekend;
        font-size: 100px;
        line-height: 80px;
        color: $color-light-blue;
        text-align: center;
        // margin-bottom: 50px;
        margin-bottom: 15px;
  
        @media (max-width: $max-width-tablet) {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        @media (max-width: $max-width-mobile) {
          font-size: 45px;
          line-height: 36px;
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
      @media (max-width: $max-width-mobile) {
        order: 2;
      }
    }
    .findself__sample-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-bottom: 3vh;
      margin-bottom: 1vh;
      order: 2;
      position: relative;
      width: 550px;
      height: 550px;
  
      .findself__sample-graphic-box {
        display: flex;
        justify-content: center;
        width: 550px;
        height: 400px;
  
        img {
          max-height: 540px;
          height: 100%;
          width: auto;
          position: relative;
          z-index: 1;
          mix-blend-mode: multiply;
          opacity: 0.8;
          margin-bottom: 0px;

          @media (max-width: $max-width-tablet) {
            max-height: 440px;
          }
          @media (max-width: $max-width-mobile) {
            max-height: 240px;
          }
        }
        @media (max-width: $max-width-tablet) {
          height: 440px;
          width: 440px;
        }
        @media (max-width: $max-width-mobile) {
          height: 240px;
          width: 240px;
        }
      }
      // Стили для реального кадра с камеры
      .findself__sample-real-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        height: 500px;
        // background-color: #baf3a3;
          
        .findself__image-real-conteiner {
          width: 450px;
          height: 450px;
          position: relative;
          // background-color: #f574ea;
          display: flex;
          justify-content: center;
          align-items: end;
          gap: 15px;
  
          img {
            height: 100%;
            width: auto;
          }
          .ring-wrapper {
            // width: 450px;
            // height: 450px;
            // position: relative;
            // border-radius: 100%;
            // border-style: none;
            // overflow: hidden;
            // @media (max-width: $max-width-tablet) {
            //   width: 350px;
            //   height: 350px;
            // }
            // @media (max-width: $max-width-mobile) {
            //   height: 260px;
            //   width: 260px;
            // }

            width: 450px;
            height: 450px;
            position: relative;
            border-radius: 100%;
            border-style: none;
            overflow: hidden;
            
            @media (max-width: $max-width-tablet) {
                width: 350px;
                height: 350px;
            }
            @media (max-width: $max-width-mobile) {
                width: 260px;
                height: 260px;
            }
          }
          .selfycam-btns-box {
            position: absolute;
            z-index: 16;
            bottom: -110px;
            width: 240px;
            display: flex;
            justify-content: center;
            gap: 20px;
          }
          @media (max-width: $max-width-tablet) {
            width: 350px;
            height: 350px;
          }
          @media (max-width: $max-width-mobile) {
            height: 260px;
            width: 260px;

          }
        }
        // Стиль для кнопки (в html коде кнопки нет)
        .findself__image-real-conteiner button {
          /* Оформление бордера кнопки */
          background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
          /* Цвет внутренней часть кнопки */
          background-origin: border-box;
          box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
          /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
          border: 3px solid transparent;
          transition: background 0.5s ease, box-shadow 0.5s ease;
          transition: background-color 1s;
          display: inline-block;
          width: auto;
          max-width: none;
          padding: 10px 20px;
          border-radius: 100px;
          user-select: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
        }
        .findself__image-real-conteiner button {
          font-family: "Golos-UI", sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 22px;
          line-height: 22px;
          text-transform: uppercase;
          color: rgb(238, 251, 255);
          user-select: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          transition: color 0.5s ease;
        }
        @media (max-width: $max-width-mobile) {
          .btn-dark p {
            font-size: 20px;
            line-height: 1.2;
          }
        }
        .findself__image-real-conteiner button {
          user-select: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
        }
        .findself__image-real-conteiner button:hover {
          background-image: linear-gradient(rgb(108, 118, 121), rgb(108, 118, 121));
          box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
        }
        .findself__image-real-conteiner button:active {
          background-image: linear-gradient(rgb(69, 86, 92), rgb(69, 86, 92));
          box-shadow: inset 0 100vw rgb(69, 86, 92);
        }
        .findself__image-real-conteiner button.btn-disabled,
        .findself__image-real-conteiner button.btn-disabled:hover,
        .findself__image-real-conteiner button.btn-disabled:active {
          /* Оформление бордера кнопки */
          background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
          /* Цвет внутренней часть кнопки */
          box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
          opacity: 0.5;
        }

        .findself__points-around-step-1,
        .findself__points-around-step-2,
        .findself__points-around-step-3 {
          position: absolute;
          z-index: 1;
          top: 0px;
          left: 0;
          mix-blend-mode: multiply;
          opacity: 0.8;
          width: 550px;
          height: 550px;
          border-radius: 100%;
  
          @media (max-width: $max-width-tablet) {
            top: 0px;
            height: 400px;
            width: 440px;
          }
          @media (max-width: $max-width-mobile) {
            top: 0px;
            height: 300px;
            width: 300px;
          }
        }
        @media (max-width: $max-width-tablet) {
          width: 350px;
          height: 350px;
        }
        @media (max-width: $max-width-mobile) {
          height: 260px;
          width: 260px;
        }
      }
      @media (max-width: $max-width-tablet) {
        width: 400px;
        height: 400px;
      }
      @media (max-width: $max-width-mobile) {
        order: 1;
        width: 300px;
        height: 300px;
      }
    }
    .findself__text-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      order: 3;
      margin-top: 75px;
  
      .findself__text-graphic-box {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 662px;
        margin-bottom: 3vh;
  
        p {
          font-size: $fz-l;
          text-align: center;
  
          @media (max-width: $max-width-mobile) {
            font-size: 16px;
          }
        }
        @media (max-width: $max-width-tablet) {
          margin-bottom: 1vh;
        }
        @media (max-width: $max-width-mobile) {
          margin-bottom: 3vh;
        }
      }
      .findself__text-step-one-box,
      .findself__text-step-two-box,
      .findself__text-step-three-box {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 310px;
        margin-bottom: 3vh;
  
        p {
          font-size: $fz-l;
          text-align: center;
  
          @media (max-width: $max-width-mobile) {
            font-size: 16px;
          }
        }
        @media (max-width: $max-width-tablet) {
          margin-bottom: 1vh;
        }
        @media (max-width: $max-width-mobile) {
          margin-bottom: 1vh;
        }
      }
      @media (max-width: $max-width-tablet) {
        margin-top: 100px;
      }
      @media (max-width: $max-width-mobile) {
        margin-top: 100px;
      }
  
    }
    .findself__btn-box {
      order: 4;
  
      p {
        text-transform: uppercase;
      }
    }
    .findself__agreed-box {
      display: flex;
      justify-content: center;
      margin-top: 2vh;
      margin-bottom: 20px;
      order: 5;
  
      p {
        font-size: $fz-xs;
        text-align: center;
  
        button {
          p {
            text-decoration: underline;
          }
        }
      }
      @media (max-width: $max-width-mobile) {
        margin-bottom: 0;
      }
    }
    @media (max-width: $max-width-mobile) {
      margin-top: 0;
    }
  }
  .findself__wait,
  .findself__done,
  .findself__failed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .findself__wait-image-box,
    .findself__done-image-box,
    .findself__failed-image-box {
      display: flex;
      justify-content: center;
      max-width: 200px;
      height: auto;
      margin-bottom: 50px;
  
      img {
        object-fit: contain;     
        position: relative;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0.6;
        margin-bottom: 0px;
      }
      @media (max-width: $max-width-tablet) {
        max-height: 30vh;
      }
      @media (max-width: $max-width-mobile) {
        max-height: 28vh;
        max-width: 170px;
      }
    }
    .findself__wait-title-box,
    .findself__done-title-box,
    .findself__failed-title-box  {
      margin-bottom: 20px;

      h2 {
        @include mv-weekend;
        font-size: $fz-xxxl2;
        color: $color-light-blue;
        text-align: center;
  
        @media (max-width: $max-width-mobile) {
          font-size: $fz-xxl2;
        }
      }
    }
  }
  
}