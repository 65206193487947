// Цвета предупреждений и фона
$color-warning: rgba(209, 59, 59, 1);
$color-pink: rgba(252, 131, 131, 1);
$color-wrong: rgba(255, 225, 225, 1);

$color-dark-gray: rgba(108, 118, 121, 1);
$color-dasty-blue: rgba(135, 156, 164, 1);
$color-steel-blue: rgba(142, 165, 174, 1);
$color-pale-blue: rgba(163, 199, 208, 1);
$color-pale-blue08: rgba(163, 199, 208, 0.8);
$color-medium-gray: rgba(187, 187, 187, 1);
$color-light-gray-v2: rgba(227, 227,227, 1);
$color-light-gray: rgba(245, 245, 245, 1);
$color-light-blue: rgba(238, 251, 255, 1);
$color-light-blue07: rgba(238, 251, 255, 0.7);
$color-light-blue-v2: rgba(232, 241, 244, 1);
$color-light-cyan: rgba(233, 244, 247, 1);

$color-white: rgba(255, 255, 255, 1);
// Основной цвет текста
$color-dark-blue-gray: rgba(69, 86, 92, 1);
$color-dark-blue-gray05: rgba(69, 86, 92, 0.5);
$color-dark-blue-gray07: rgba(69, 86, 92, 0.7);
$color-cool-stone: rgba(116, 140, 149, 1);
$color-cool-stone05: rgba(116, 140, 149, 0.5);

$color-misty-sea: rgba(50, 87, 96, 0.35);
$color-stormy-sea: rgba(50, 87, 96, 1);

// Цвета кнопок Модального окна
$color-btn-modal: rgba(116, 140, 149, 1);
$color-btn-modal-active: rgba(215, 244, 251, 0.6);
$color-btn-modal-disabled: rgba(116, 140, 149, 0.5);

// Объявляем переменные для размера шрифта
$fz-xxs2: 12px;
$fz-xxs: 14px;
$fz-xs: 16px;
$fz-s: 18px;
$fz-m: 20px;
$fz-m2: 22px;
$fz-l: 24px;
$fz-xl: 28px;
$fz-xxl: 32px;
$fz-xxl2: 45px;
$fz-xxxl: 50px;
$fz-xxxl2: 64px;


// Размеры для адаптации к ширине окна
$max-width-tablet: 1199px;
$max-width-mobile: 767px;

// Тени
$box-shadow-lite: 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
$box-shadow-normal: 2px 1px 2px 0px rgba(0, 0, 0, 0.25);


// Отступы
$margit-bottom-xs: 2vh;

// Не удалять:
// Z-index Элементов и Компонентов:
// HeaderClient: 50
// LeftBar: 50
// Footer: 30
// BottomBar: 20
// (Видео с камеры в FindSelf) video: 15  and canvas:16 