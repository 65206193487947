@charset "UTF-8";
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI-VF";
  src: url("/src/fonts/Golos-UI-VF-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Medium-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Golos-UI";
  src: url("/src/fonts/Golos-UI-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "MV-WEEKEND";
  src: url("/src/fonts/MV-WEEKEND.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
html {
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

body {
  font-weight: 400;
  color: rgb(69, 86, 92);
  height: 100%;
  width: 100vw;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img {
  display: block;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  padding: 0;
  margin: 0;
}

h2, h3, h4, h5, h6, p, ul, li {
  font-family: "Golos-UI", sans-serif;
}

p {
  font-size: 20px;
  line-height: 1.2;
  color: rgb(69, 86, 92);
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  border-style: none;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.opacity30 {
  opacity: 0.3;
}

.opacity30:hover {
  opacity: 0.7;
}

.opacity30:active {
  opacity: 1;
}

.opacity50 {
  opacity: 0.5;
}

.opacity50:hover {
  opacity: 0.65;
}

.opacity60 {
  opacity: 0.6;
}

.opacity60:hover {
  opacity: 1;
}

.opacity70 {
  opacity: 0.7;
}

.opacity70:hover {
  opacity: 1;
}

.container-m {
  max-width: 950px;
  width: 100%;
}

.btn-modal {
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
}
.btn-modal p {
  font-family: "Golos-UI", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  color: rgb(238, 251, 255);
}
@media (max-width: 767px) {
  .btn-modal p {
    font-size: 20px;
    line-height: 1.2;
  }
}

/* Исправляем возможные расхождения через медиазапросы для iOS */
/* Специальные стили для iOS Safari */
.btn-standard {
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
}
.btn-standard p {
  font-family: "Golos-UI", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  color: rgb(69, 86, 92);
}
@media (max-width: 767px) {
  .btn-standard p {
    font-size: 20px;
    line-height: 1.2;
  }
}

.button-v2 {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
.button-v2 p {
  color: rgb(238, 251, 255);
  font-size: 14px;
  line-height: 1;
}

.button-v2.not-active {
  background-color: rgba(116, 140, 149, 0.5);
  pointer-events: none;
}

.button-v2.active {
  background-color: rgb(116, 140, 149);
}

.button-v2.active:hover {
  border-color: rgb(69, 86, 92);
}

.button-v2.active:active {
  background-color: rgb(69, 86, 92);
  border-color: rgb(69, 86, 92);
}

/* Микс стиль - Кнопка Из тёмнго фона в светлый */
.btn-dark-to-light {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, #EEFBFF -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, #EEFBFF 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-dark-to-light p {
  color: rgb(238, 251, 255);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: color 0.5s ease;
}

.btn-dark-to-light * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-dark-to-light:hover {
  opacity: 0.8;
}

.btn-dark-to-light:active {
  background-image: linear-gradient(270.96deg, #EEFBFF -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, #EEFBFF 106.06%);
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(215, 244, 251, 0.6);
}
.btn-dark-to-light:active p {
  color: rgb(238, 251, 255);
}

.btn-dark {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 3px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  transition: background-color 1s;
  display: inline-block;
  flex-grow: 0;
  width: auto;
  max-width: none;
  padding: 10px 20px;
  border-radius: 100px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.btn-dark p {
  font-family: "Golos-UI", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  color: rgb(238, 251, 255);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: color 0.5s ease;
}
@media (max-width: 767px) {
  .btn-dark p {
    font-size: 20px;
    line-height: 1.2;
  }
}

.btn-dark * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-dark:hover {
  background-image: linear-gradient(rgb(108, 118, 121), rgb(108, 118, 121));
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
}

.btn-dark:active {
  background-image: linear-gradient(rgb(69, 86, 92), rgb(69, 86, 92));
  box-shadow: inset 0 100vw rgb(69, 86, 92);
}

.btn-dark.btn-disabled,
.btn-dark.btn-disabled:hover,
.btn-dark.btn-disabled:active {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  /* Цвет внутренней часть кнопки */
  box-shadow: inset 0 100vw rgb(116, 140, 149), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  opacity: 0.5;
}

/* Микс стиль - Кнопка Из светлого фона в тёмный */
.btn-light-to-dark {
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(215, 244, 251, 0.6);
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-light-to-dark * {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-light-to-dark:hover {
  box-shadow: inset 0 100vw rgba(194, 220, 226, 0.6);
}

.btn-light-to-dark p {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-light-to-dark:active {
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  background-origin: border-box;
  box-shadow: inset 0 100vw rgb(116, 140, 149);
}

.btn-light-to-dark:active p {
  color: rgb(238, 251, 255);
}

.btn-disabled {
  /* Оформление бордера кнопки */
  background-image: linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%);
  /* Цвет внутренней часть кнопки */
  background-origin: border-box;
  box-shadow: inset 0 100vw rgba(116, 140, 149, 0.5), 5px 10px 10px 0px rgba(0, 0, 0, 0.05);
  /* Прозрачная рамка, так что самый край кнопки просвечивает насквозь */
  border: 2px solid transparent;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.link-disabled {
  cursor: default !important;
  opacity: 0.5;
}

.btn-padding-10-20 {
  padding: 10px 20px;
}

.btn-padding-10-40 {
  padding: 10px 40px;
}

.modal-input-box .modal-label,
.input__box .modal-label {
  font-family: "Golos-UI", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: rgb(69, 86, 92);
}
@media (max-width: 767px) {
  .modal-input-box .modal-label,
  .input__box .modal-label {
    font-size: 16px;
    line-height: 1.2;
  }
}
.modal-input-box .modal-input,
.input__box .modal-input {
  margin-top: 5px;
  padding: 18px 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid transparent;
  background-color: rgb(255, 255, 255);
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: rgb(50, 87, 96);
  position: relative;
}
@media (max-width: 767px) {
  .modal-input-box .modal-input,
  .input__box .modal-input {
    font-size: 16px;
    line-height: 1.2;
  }
}
.modal-input-box .modal-input-wrong,
.input__box .modal-input-wrong {
  background-color: rgb(255, 225, 225);
}
.modal-input-box .modal-input:focus,
.input__box .modal-input:focus {
  border: 1px solid rgba(113, 137, 143, 0.5);
  outline: none;
}
.modal-input-box .modal-input::-moz-placeholder, .input__box .modal-input::-moz-placeholder {
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: rgba(50, 87, 96, 0.35);
}
.modal-input-box .modal-input::placeholder,
.input__box .modal-input::placeholder {
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: rgba(50, 87, 96, 0.35);
}
@media (max-width: 767px) {
  .modal-input-box .modal-input::-moz-placeholder, .input__box .modal-input::-moz-placeholder {
    font-size: 16px;
    line-height: 1.2;
  }
  .modal-input-box .modal-input::placeholder,
  .input__box .modal-input::placeholder {
    font-size: 16px;
    line-height: 1.2;
  }
}
.modal-input-box .hint-box p,
.input__box .hint-box p {
  font-family: "Golos-UI", sans-serif;
  font-size: 18px;
  color: rgb(69, 86, 92);
}
@media (max-width: 767px) {
  .modal-input-box .hint-box p,
  .input__box .hint-box p {
    font-size: 16px;
    line-height: 1.2;
  }
}

.password-box {
  position: relative;
}

.show-dont-show-password {
  position: absolute;
  z-index: 4;
  bottom: 18px;
  right: 22px;
  border-style: none;
  background: transparent;
  padding: 0;
  height: 24px;
  width: auto;
}
.show-dont-show-password img {
  height: 100%;
  width: auto;
}

.wrong-data-box {
  display: flex;
  justify-content: center;
  padding: 1px;
  margin-top: 3px;
}
.wrong-data-box p {
  font-size: 16px;
  color: rgb(209, 59, 59);
}

.like-modal-blur {
  border-radius: 20px;
  border: 3px solid transparent;
  background: linear-gradient(rgba(238, 251, 255, 0.7), rgba(238, 251, 255, 0.7)) padding-box, linear-gradient(270.96deg, rgb(238, 251, 255) -5.22%, rgba(255, 255, 255, 0.2) 15.36%, rgba(255, 255, 255, 0.2) 79.35%, rgb(238, 251, 255) 106.06%) border-box;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.line-box {
  display: flex;
  align-items: center;
  flex: 1; /* Занимает всё оставшееся пространство */
  height: 100%;
  width: 20px;
}
.line-box .line-hr {
  height: 1px;
  width: 100%;
  background-color: rgb(69, 86, 92);
  margin: 0;
  padding: 0;
  border-style: none;
}

.account__line-box {
  display: flex;
  justify-content: center;
  min-width: 6px;
  width: 5%;
}
.account__line-box .account__line-hr {
  height: 100%;
  border-right: 2px dashed rgb(142, 165, 174);
}
@media (max-width: 1199px) {
  .account__line-box {
    display: none;
  }
}

.switch-box {
  display: flex;
  align-items: center;
  flex: 0 0 auto; /* Занимает только необходимое пространство */
  height: 100%;
  /* Ручная настройка внешнего вида */
}
.switch-box .switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}
.switch-box .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-box .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(163, 199, 208, 0.8);
  transition: 0.4s;
}
.switch-box .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: rgb(69, 86, 92);
  transition: 0.4s;
}
.switch-box input:checked + .slider {
  background-color: rgb(238, 251, 255);
}
.switch-box input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.switch-box input:checked + .slider:before {
  transform: translateX(15px);
}
.switch-box .slider.round {
  border-radius: 25px;
}
.switch-box .slider.round:before {
  border-radius: 50%;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  /* Меняем изображение при активации чекбокса */
}
.custom-checkbox .custom-checkbox-input {
  display: none;
}
.custom-checkbox .custom-checkbox-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../../../../assets/images/image-checkbox-uncheck.png");
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 25px;
}
@media (max-width: 767px) {
  .custom-checkbox .custom-checkbox-icon {
    width: 15px;
    height: 15px;
  }
}
.custom-checkbox .custom-checkbox-input:checked + .custom-checkbox-icon {
  background-image: url("../../../../assets/images/image-checkbox-checked.png");
}
@media (max-width: 767px) {
  .custom-checkbox {
    width: 15px;
    height: 15px;
  }
}

.payment__cards-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 330px;
  min-height: 70px;
  padding: 10px;
  border-radius: 10px;
  border-style: none;
  background-color: rgba(238, 251, 255, 0.7);
}
.payment__cards-item .payment__cards-item-left {
  display: flex;
  gap: 15px;
}
.payment__cards-item .payment__cards-item-left .payment__cards-item-logo-box {
  width: 50px;
}
.payment__cards-item .payment__cards-item-left .payment__cards-item-logo-box img {
  width: 100%;
  height: auto;
}
.payment__cards-item .payment__cards-item-left .payment__cards-item-name-bank {
  display: flex;
  align-items: center;
}
.payment__cards-item .payment__cards-item-left .payment__cards-item-name-bank p {
  font-size: 18px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .payment__cards-item .payment__cards-item-left .payment__cards-item-name-bank p {
    font-size: 16px;
  }
}
.payment__cards-item .payment__cards-item-right {
  display: flex;
  align-items: flex-start;
  height: 100%;
  gap: 10px;
}
.payment__cards-item .payment__cards-item-right .payment__cards-item-card-number p {
  font-size: 16px;
}
.payment__cards-item .payment__cards-item-right .image__checkbox-select-box {
  position: relative;
  width: 24px;
  height: 24px;
}
.payment__cards-item .payment__cards-item-right .image__checkbox-select-box .image__checkbox-select-method {
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0px;
  width: 24px;
  height: 24px;
  border-radius: 10px;
}
.payment__cards-item .payment__cards-item-right .image__checkbox-select-box .custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  /* Меняем изображение при активации чекбокса */
}
.payment__cards-item .payment__cards-item-right .image__checkbox-select-box .custom-checkbox .custom-checkbox-input {
  display: none;
}
.payment__cards-item .payment__cards-item-right .image__checkbox-select-box .custom-checkbox .custom-checkbox-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../../../assets/images/image-checkbox-uncheck.png");
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 25px;
}
.payment__cards-item .payment__cards-item-right .image__checkbox-select-box .custom-checkbox .custom-checkbox-input:checked + .custom-checkbox-icon {
  background-image: url("../../../../assets/images/image-checkbox-checked.png");
}

.account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
}
.account .account__title-info-box {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  position: absolute;
  top: -35px;
  left: 0px;
}
.account .account__title-info-box h3 {
  font-size: 28px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .account .account__title-info-box h3 {
    font-size: 20px;
    text-transform: uppercase;
  }
}
@media (max-width: 1199px) {
  .account .account__title-info-box {
    position: static;
    top: auto;
    left: auto;
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .account .account__title-info-box {
    margin-left: 0;
  }
}
.account .account__place {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 50px;
}
.account .account__place .account__avatar-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 110px;
  margin-bottom: 30px;
}
.account .account__place .account__avatar-box .account__avatar-wrapper {
  width: 131px;
  height: 106px;
  position: relative;
}
.account .account__place .account__avatar-box .account__avatar-wrapper .account__avatar-mini-menu-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 9px;
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-style: none;
  width: 40px;
  height: 30px;
  background-color: rgb(238, 251, 255);
}
.account .account__place .account__avatar-box .account__avatar-wrapper .account__avatar-mini-menu-box img {
  height: 20px;
  width: auto;
  opacity: 0.3;
}
.account .account__place .account__avatar-box .account__avatar-wrapper .account__avatar-mini-menu-box:hover {
  cursor: pointer;
}
.account .account__place .account__avatar-box .account__avatar-wrapper .account__avatar-mini-menu-box:hover img {
  opacity: 0.7;
}
.account .account__place .account__avatar-box .account__avatar-wrapper .account__avatar-mini-menu-box:active img {
  opacity: 1;
}
.account .account__place .account__avatar-box .account__avatar-wrapper .account__avatar-photo-box {
  width: 106px;
  height: 106px;
  border-width: 3px;
  border-style: solid;
  border-radius: 100%;
  border-color: rgb(238, 251, 255);
  position: absolute;
  z-index: 11;
  top: 0px;
  left: 0px;
  background-color: #a09191;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account .account__place .account__avatar-box .account__avatar-wrapper .account__avatar-photo-box img {
  height: 69px;
  width: auto;
}
@media (max-width: 1199px) {
  .account .account__place .account__avatar-box {
    justify-content: center;
    margin-bottom: 20px;
  }
}
.account .account__place .account__place-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.account .account__place .account__place-center .account__dates {
  width: 100%;
  min-width: 360px;
  max-width: 25%;
}
.account .account__place .account__place-center .account__dates .account__name-lastname {
  max-width: 360px;
  width: 100%;
  height: 115px;
  padding: 1px;
  display: flex;
  flex-direction: column;
}
.account .account__place .account__place-center .account__dates .account__name-lastname .modal-input-box {
  width: 100%;
}
.account .account__place .account__place-center .account__dates .account__name-lastname .modal-input-box .input-wrapper {
  width: 100%;
  position: relative;
}
.account .account__place .account__place-center .account__dates .account__name-lastname .modal-input-box .input-wrapper .modal-input {
  margin-top: 5px;
  padding: 18px 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid transparent;
  background-color: rgb(255, 255, 255);
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: rgb(50, 87, 96);
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__dates .account__name-lastname .modal-input-box .input-wrapper .modal-input {
    font-size: 16px;
    line-height: 1.2;
  }
}
.account .account__place .account__place-center .account__dates .account__name-lastname .modal-input-box .input-wrapper .input-pencil-box {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.account .account__place .account__place-center .account__dates .account__name-lastname .modal-input-box .input-wrapper .input-pencil-box img {
  height: 20px;
  width: auto;
}
.account .account__place .account__place-center .account__dates .account__second-string {
  max-width: 360px;
  width: 100%;
  height: 115px;
  padding: 1px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__birthday-box {
  max-width: 180px;
  width: 100%;
  height: 115px;
  padding: 1px;
  display: flex;
  flex-direction: column;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__birthday-box .modal-input-box {
  width: 100%;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__birthday-box .modal-input-box .input-wrapper {
  width: 100%;
  position: relative;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__birthday-box .modal-input-box .input-wrapper .modal-input {
  margin-top: 5px;
  padding: 18px 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid transparent;
  background-color: rgb(255, 255, 255);
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: rgb(50, 87, 96);
  position: relative;
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__dates .account__second-string .account__birthday-box .modal-input-box .input-wrapper .modal-input {
    font-size: 16px;
    line-height: 1.2;
  }
}
.account .account__place .account__place-center .account__dates .account__second-string .account__birthday-box .modal-input-box .input-wrapper .input-pencil-box {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__birthday-box .modal-input-box .input-wrapper .input-pencil-box img {
  height: 20px;
  width: auto;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__gender-box p {
  font-size: 18px;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__gender-box .account__gender-wrapper {
  display: flex;
  gap: 10px;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__gender-box .account__gender-wrapper .account__gender-item {
  height: 60px;
  margin-top: 5px;
}
.account .account__place .account__place-center .account__dates .account__second-string .account__gender-box .account__gender-wrapper .account__gender-item .checkbox__wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__dates .account__second-string {
    max-width: 320px;
    gap: 10px;
  }
}
.account .account__place .account__place-center .account__dates .account__phone {
  max-width: 360px;
  width: 100%;
  height: 115px;
  padding: 1px;
  display: flex;
  flex-direction: column;
}
.account .account__place .account__place-center .account__dates .account__phone .modal-input-box {
  width: 100%;
}
.account .account__place .account__place-center .account__dates .account__phone .modal-input-box .input-wrapper {
  width: 100%;
  position: relative;
}
.account .account__place .account__place-center .account__dates .account__phone .modal-input-box .input-wrapper .modal-input {
  margin-top: 5px;
  padding: 18px 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid transparent;
  background-color: rgb(255, 255, 255);
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: rgb(50, 87, 96);
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__dates .account__phone .modal-input-box .input-wrapper .modal-input {
    font-size: 16px;
    line-height: 1.2;
  }
}
.account .account__place .account__place-center .account__dates .account__phone .modal-input-box .input-wrapper .input-pencil-box {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.account .account__place .account__place-center .account__dates .account__phone .modal-input-box .input-wrapper .input-pencil-box img {
  height: 20px;
  width: auto;
}
.account .account__place .account__place-center .account__dates .account__email {
  max-width: 360px;
  width: 100%;
  height: 115px;
  padding: 1px;
  display: flex;
  flex-direction: column;
}
.account .account__place .account__place-center .account__dates .account__email .modal-input-box {
  width: 100%;
}
.account .account__place .account__place-center .account__dates .account__email .modal-input-box .input-wrapper {
  width: 100%;
  position: relative;
}
.account .account__place .account__place-center .account__dates .account__email .modal-input-box .input-wrapper .modal-input {
  margin-top: 5px;
  padding: 18px 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid transparent;
  background-color: rgb(255, 255, 255);
  font-family: "Golos-UI", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: rgb(50, 87, 96);
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__dates .account__email .modal-input-box .input-wrapper .modal-input {
    font-size: 16px;
    line-height: 1.2;
  }
}
.account .account__place .account__place-center .account__dates .account__email .modal-input-box .input-wrapper .input-pencil-box {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.account .account__place .account__place-center .account__dates .account__email .modal-input-box .input-wrapper .input-pencil-box img {
  height: 20px;
  width: auto;
}
@media (max-width: 1199px) {
  .account .account__place .account__place-center .account__dates {
    max-width: 360px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__dates {
    max-width: 320px;
    min-width: 300px;
    margin: 0;
    margin-bottom: 30px;
  }
}
.account .account__place .account__place-center .account__customizing {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 290px;
  max-width: 25%;
}
.account .account__place .account__place-center .account__customizing .account__face-entry {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  height: 22px;
  margin-bottom: 50px;
}
.account .account__place .account__place-center .account__customizing .account__face-entry .text-box {
  display: flex;
  align-items: center;
  flex: 0 0 auto; /* Занимает только необходимое пространство */
  height: 100%;
  padding: 1px;
}
.account .account__place .account__place-center .account__customizing .account__face-entry .text-box p {
  font-size: 18px;
}
@media (max-width: 1199px) {
  .account .account__place .account__place-center .account__customizing .account__face-entry {
    margin-bottom: 20px;
  }
}
.account .account__place .account__place-center .account__customizing .account__text-notifications-box {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 22px;
  margin-bottom: 30px;
}
.account .account__place .account__place-center .account__customizing .account__text-notifications-box p {
  font-size: 18px;
}
@media (max-width: 1199px) {
  .account .account__place .account__place-center .account__customizing .account__text-notifications-box {
    margin-bottom: 10px;
  }
}
.account .account__place .account__place-center .account__customizing .account__customizing-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__customizing-item {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  height: 22px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__customizing-item .text-box {
  display: flex;
  align-items: center;
  flex: 0 0 auto; /* Занимает только необходимое пространство */
  height: 100%;
  padding: 1px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__customizing-item .text-box p {
  font-size: 16px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__method-of-receipt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  height: 22px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__method-of-receipt .text-box {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__method-of-receipt .text-box p {
  font-size: 16px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__method-of-receipt .check-boxes-wrapper {
  display: flex;
  gap: 15px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__method-of-receipt .check-boxes-wrapper .check-boxes-item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.account .account__place .account__place-center .account__customizing .account__customizing-down .account__method-of-receipt .check-boxes-wrapper .check-boxes-item p {
  font-size: 16px;
}
@media (max-width: 1199px) {
  .account .account__place .account__place-center .account__customizing .account__customizing-down {
    gap: 20px;
  }
}
@media (max-width: 1199px) {
  .account .account__place .account__place-center .account__customizing {
    margin: 0 auto 50px;
    max-width: 360px;
  }
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__customizing {
    max-width: 320px;
    min-width: 300px;
    margin: 0;
    margin-bottom: 30px;
  }
}
.account .account__place .account__place-center .account__payment-methods {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 360px;
  max-width: 30%;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left {
  min-width: 360px;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left p {
  font-size: 18px;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box {
  margin-top: 20px;
  height: 100%;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-item {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-item .btn-garbage-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-height: 70px;
  width: 25px;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-item .btn-garbage-box img {
  height: 25px;
  width: auto;
  cursor: pointer;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-add-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  min-height: 70px;
  padding: 10px;
  border-radius: 10px;
  border-style: none;
  background-color: rgba(238, 251, 255, 0.7);
  cursor: pointer;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-add-card .payment__add-card-box p {
  font-size: 18px;
  opacity: 0.5;
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-add-card {
    max-width: 320px;
    min-width: 300px;
    margin: 0;
    margin-bottom: 30px;
  }
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-add-card:hover .payment__add-card-box p {
  font-size: 18px;
  opacity: 0.8;
}
.account .account__place .account__place-center .account__payment-methods .account__payment-methods-left .account__payment-methods-box .account__payment-method-add-card:active .payment__add-card-box p {
  font-size: 18px;
  opacity: 1;
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__payment-methods .account__payment-methods-left {
    max-width: 320px;
    min-width: 300px;
    margin: 0;
    margin-bottom: 30px;
  }
}
@media (max-width: 1199px) {
  .account .account__place .account__place-center .account__payment-methods {
    margin-top: 30px;
    max-width: 360px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .account .account__place .account__place-center .account__payment-methods {
    max-width: 320px;
    min-width: 300px;
    margin: 0;
    margin-bottom: 30px;
  }
}
@media (max-width: 1199px) {
  .account .account__place .account__place-center {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .account .account__place .account__place-center {
    align-items: center;
  }
}
.account .account__place .account__place-down {
  min-height: 80px;
  height: 100%;
  max-height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account .account__place .account__place-down .account__btn-delete-account-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1px;
  cursor: pointer;
  height: 35px;
}
.account .account__place .account__place-down .account__btn-delete-account-wrapper .image-box {
  height: 35px;
  width: 30px;
}
.account .account__place .account__place-down .account__btn-delete-account-wrapper .image-box img {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
  mix-blend-mode: multiply;
  opacity: 0.8;
}
.account .account__place .account__place-down .account__btn-delete-account-wrapper .text-box {
  height: 35px;
}
.account .account__place .account__place-down .account__btn-delete-account-wrapper .text-box p {
  font-size: 20px;
}
@media (max-width: 767px) {
  .account .account__place {
    margin-top: 20px;
  }
}/*# sourceMappingURL=Account.css.map */