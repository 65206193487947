@import "../App/var";
@import "../App/App.scss";

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  width: 100%;
  height: 80px;
  background-color: $color-light-blue;
  border-top: 3px solid; 
  border-image: 
            linear-gradient(270.96deg, 
            #EEFBFF -5.22%, rgba(255, 255, 255, 0.2) 15.36%, 
            rgba(255, 255, 255, 0.2) 79.35%, 
            #EEFBFF 106.06%) 1;
  border-image-slice: 1;
  padding-right: 50px;

  .footer__left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .footer__text-add-box,
    .footer__text-select-box {
      display: flex;
      gap: 20px;

      .footer__text-add,
      .footer__text-select {
        font-size: $fz-l;
  
        .footer__volume-photo,
        .footer__cost-photos {
          font-weight: 700;
        }
        @media (max-width: $max-width-mobile) {
          font-size: $fz-xs;
        }
      }
      @media (max-width: $max-width-mobile) {
        flex-direction: column;
        gap: 5px;
      }
    }
  }
  .footer__right {
    display: flex;
    justify-content: center;
    align-items: center;

    .footer__right-btn-part-text {
      display: inline-block;

      @media (max-width: $max-width-mobile) {
        display: none;
      }
    }
  }
  @media (max-width: $max-width-mobile) {
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }
}