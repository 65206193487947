@import "../../../../../client/src/Components/App/var";
@import "../../../../../client/src/Components/App/App.css";

.confirm-cart-photo-delete {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: $fz-m;
        max-width: 240px;
        text-align: center;
    }

    .confirm-cart-photo-delete__buttons-box {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        gap: 20px;
    }
}