@import "../../App/var";
@import "../../App/App.css";

.photo-carousel {
  // background-color: aqua;
  display: flex;
  flex-direction: column;
  height: 100vh;

  // Верхний блок
  .photo-carousel__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: calc(100% - 80px);
    height: 100%;
    width: 100%;

    .photo-carousel__title-box {
      display: flex;
      flex-direction: column;
      margin-top: 70px;
      margin-bottom: 20px;
      padding: 1px;
      width: 100%;
      max-width: calc(100vw - 160px);

      p {
        font-size: 16px;
        text-align: center;
        @media (max-width: 1199px) {
          padding-left: 15px;
          padding-right: 30px;
          text-align: left;
        }
        @media (max-width: 767px) {
            font-size: 14px;
            padding-left: 15px;
            padding-right: 30px;
            text-align: left;
        }
      }
      .photo-carousel__title-date {
        font-weight: 700;
        @media (max-width: 767px) {
            font-weight: 400;
        }
      }
      .photo-carousel__title-dop-box {
        display: none;
        @media (max-width: 1199px) {
          display: flex;
          margin-top: 30px;
        }
      }
      @media (max-width: 1199px) {
        width: 100%;
        margin-top: 45px;
        min-height: 50px;
        max-width: 100%;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 45px;
        min-height: 80px;
        max-width: 100%;
      }
    }
    .photo-carousel__photo-box {
      display: flex;
      align-items: center;
      justify-content: center;
      // max-height: calc(100% - 220px);
      max-height: 97%;
      // max-width: calc(100vw - 160px);
      max-width: 97%;
      padding: 1px;
      position: relative;
      background-color: $color-dark-blue-gray05;
      
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; // Важно: сохраняет пропорции и "вписывает" изобр в блок
        display: block;
      }
      // Левая кнопка
      .btn-arrow-left-box {
        position: absolute;
        bottom: 50%;
        left: 0px;
        transform: translateY(+50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 100px 5px;
        background-color: rgba(255, 255, 255, 0.5);
        transition: background-color 0.5s ease;

        .photo-carousel__photo-arrow-left {
          width: 80px;
          height: auto;
          opacity: 0.7;
          transition: opacity 0.5s ease;
          @media (max-width: $max-width-mobile) {
            width: 30px;
          }
        }

        @media (max-width: $max-width-mobile) {
          padding: 30px 5px;
        }
      }
      .btn-arrow-left-box:hover {
        background-color: rgba(255, 255, 255, 0.7);
        .photo-carousel__photo-arrow-left {
          opacity: 1;
        }
      }
      .btn-arrow-left-box:active {
        background-color: rgba(255, 255, 255, 1);
        .photo-carousel__photo-arrow-left {
          opacity: 1;
        }
      }
      // Правая кнопка
      .btn-arrow-right-box {
        position: absolute;
        bottom: 50%;
        right: 0px;
        transform: translateY(+50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 100px 5px;
        background-color: rgba(255, 255, 255, 0.5);
        transition: background-color 0.5s ease;

        .photo-carousel__photo-arrow-right {
          width: 80px;
          height: auto;
          opacity: 0.7;
          transition: opacity 0.5s ease;
          @media (max-width: $max-width-mobile) {
            width: 30px;
          }
        }
        @media (max-width: $max-width-mobile) {
          padding: 30px 5px;
        }
      }
      .btn-arrow-right-box:hover {
        background-color: rgba(255, 255, 255, 0.7);
        .photo-carousel__photo-arrow-right {
          opacity: 1;
        }
      }
      .btn-arrow-right-box:active {
        background-color: rgba(255, 255, 255, 1);
        .photo-carousel__photo-arrow-right {
          opacity: 1;
        }
      }
      // Чек-боксы
      .image__checkbox-select-photo-box {

        .image__checkbox-select-photo {
          position: absolute;
          z-index: 2;
          top: 10px;
          right: 10px;
          width: 24px;
          height: 24px;
          border-radius: 10px;
  
          @media (max-width: $max-width-mobile) {
            top: 5px;
            right: 5px;
          }
        }
        .image__checkbox-favourites-photo {
          position: absolute;
          z-index: 2;
          bottom: 10px;
          right: 10px;
          width: 24px;
          height: 24px;
          border-radius: 10px;
  
          @media (max-width: $max-width-mobile) {
            bottom: 5px;
            right: 5px;
          }
        }
        .image__checkbox-download-photo {
          position: absolute;
          z-index: 2;
          top: 10px;
          right: 10px;
          width: 24px;
          height: 24px;
          border-radius: 10px;
  
          @media (max-width: $max-width-mobile) {
            bottom: 5px;
            right: 5px;
          }
        }
        .custom-checkbox {
          display: flex;
          align-items: center;
          gap: 5px;
  
          // Скрываем стандартный чек-бокс
          .custom-checkbox-input {
            display: none;
          }
          // Показываем картинку - кастомного чек-бокса
          .custom-checkbox-icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('../../../assets/images/image-checkbox-uncheck.png');
            background-size: cover;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 25px;
            opacity: 0.5;
          }
          /* Меняем изображение при активации чекбокса */
          .custom-checkbox-input:checked + .custom-checkbox-icon {
            background-image: url('../../../assets/images/image-checkbox-checked.png');
            opacity: 1;
          }
        }
        /* Кастомный чекбокс - Избранное */
        .custom-checkbox-favourites {
          display: flex;
          align-items: center;
          gap: 5px;
  
          .custom-checkbox-input-favourites {
            display: none;
          }
          /* Стили для кастомной галочки */
          .custom-checkbox-icon-favourites {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('../../../assets/images/image-checkbox-uncheck-favourite.svg');
            background-size: cover;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 25px;
            opacity: 0.5;
          }
          /* Меняем изображение при активации чекбокса */
          .custom-checkbox-input-favourites:checked + .custom-checkbox-icon-favourites {
            background-image: url('../../../assets/images/image-checkbox-favourites-v2.svg');
            opacity: 1;
          }
        }
        // Кастомный чекбокс - Скачать
        .custom-checkbox-download {
          display: flex;
          align-items: center;
          gap: 5px;
  
          .custom-checkbox-input-download {
            display: none;
          }
          /* Стили для кастомной галочки */
          .custom-checkbox-icon-download {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('../../../assets/images/image-checkbox-uncheck.png');
            background-size: cover;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 25px;
          }
          /* Меняем изображение при активации чекбокса */
          .custom-checkbox-input-download:checked + .custom-checkbox-icon-download {
            background-image: url('../../../assets/images/image-checkbox-download.svg');
          }
        }
      }

      @media (max-width: $max-width-tablet) {
        // max-width: 100vw;
      }
      @media (max-width: $max-width-mobile) {
        // max-height: 69%;
        // max-width: 100vw;
      }
    }
        // @media (max-width: $max-width-mobile) {
        //   max-height: 72%;
        //   max-width: 100vw;
        // }
    .photo-carousel__info-box {
      display: flex;
      flex-shrink: 0;
      max-width: 740px;
      // background-color: #4af8f8;
      padding: 1px;
      margin-top: 20px;
      margin-bottom: 30px;
      p {
        font-size: 16px;
        @media (max-width: 767px) {
            font-size: 14px;
        }
      }

      @media (max-width: 1199px) {
        display: none;
        padding-top: 0px;
      }
      @media (max-width: 767px) {
        margin-left: 15px;
        margin-right: 15px;
      } 
    }
    .photo-carousel__for-balance {
      display: none;
      height: 20px;
      @media (max-width: $max-width-tablet) {
        display: none;
        height: 50px;
      }
    }
    @media (max-width: $max-width-tablet) {
      // justify-content: space-between;
      .photo-carousel__title-box {
        order: 1;
      }
      .photo-carousel__photo-box {
        order: 3;
      }
      .photo-carousel__info-box {
        order: 2;
      }
      .photo-carousel__for-balance {
        order: 4;
      }
    }
  }
  .photo-carousel__footer {
      min-height: 80px;
      height: 80px;
      // background-color: #f0337b;
  }
  @media (max-width: $max-width-tablet) {
    justify-content: center;
    align-items: center;
  }
}

// Copy 2025-08-28_15-20
// .photo-carousel {
//   // background-color: aqua;
//   display: flex;
//   flex-direction: column;
//   height: 100vh;

//   // Верхний блок
//   .photo-carousel__top {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: calc(100% - 80px);

//     .photo-carousel__title-box {
//       display: flex;
//       flex-direction: column;
//       margin-top: 70px;
//       margin-bottom: 20px;
//       padding: 1px;
//       width: 100%;
//       max-width: calc(100vw - 160px);

//       p {
//         font-size: 16px;
//         text-align: center;
//         @media (max-width: 1199px) {
//           padding-left: 15px;
//           padding-right: 30px;
//           text-align: left;
//         }
//         @media (max-width: 767px) {
//             font-size: 14px;
//             padding-left: 15px;
//             padding-right: 30px;
//             text-align: left;
//         }
//       }
//       .photo-carousel__title-date {
//         font-weight: 700;
//         @media (max-width: 767px) {
//             font-weight: 400;
//         }
//       }
//       .photo-carousel__title-dop-box {
//         display: none;
//         @media (max-width: 1199px) {
//           display: flex;
//           margin-top: 30px;
//         }
//       }
//       @media (max-width: 1199px) {
//         width: 100%;
//         margin-top: 45px;
//         min-height: 50px;
//         max-width: 100%;
//       }
//       @media (max-width: 767px) {
//         width: 100%;
//         margin-top: 45px;
//         min-height: 80px;
//         max-width: 100%;
//       }
//     }
//     .photo-carousel__photo-box {
//       display: flex;
//       align-items: center;
//       // height: 100%;
//       max-height: calc(100% - 220px);
//       max-width: calc(100vw - 160px);
//       padding: 1px;
//       position: relative;
//       // background-color: #83f561;
//       background-color: $color-dark-blue-gray05;
      
//       img {
//         width: auto;
//         max-width: 100%;
//         height: 100%;
//         max-height: 100%;
//         object-fit: contain;
//       }
//       // old version
//       // .photo-carousel__photo-arrow-left {
//       //   position: absolute;
//       //   bottom: 50%;
//       //   left: -60px;
//       //   transform: translateY(+50%);
//       //   width: 40px;
//       //   height: auto;
//       //   cursor: pointer;
//       //   @media (max-width: 1199px) {
//       //       bottom: -25px;
//       //       left: 15px;
//       //   }
//       // }
//       // .photo-carousel__photo-arrow-right {
//       //   position: absolute;
//       //   bottom: 50%;
//       //   right: -60px;
//       //   transform: translateY(+50%) rotate(180deg);
//       //   width: 40px;
//       //   height: auto;
//       //   cursor: pointer;
//       //   @media (max-width: 1199px) {
//       //       bottom: -33px;
//       //       right: 15px;
//       //   }
//       // }
//       .photo-carousel__photo-arrow-left {
//         position: absolute;
//         bottom: 50%;
//         left: 0px;
//         transform: translateY(+50%);
//         width: 40px;
//         height: auto;
//         cursor: pointer;
//         background-color: rgba(255, 255, 255, 1);
//         @media (max-width: 1199px) {
//             bottom: -25px;
//             left: 15px;
//         }
//       }
//       .photo-carousel__photo-arrow-right {
//         position: absolute;
//         bottom: 50%;
//         right: 0px;
//         transform: translateY(+50%);
//         width: 40px;
//         height: auto;
//         cursor: pointer;
//         background-color: rgba(255, 255, 255, 1);
//         @media (max-width: 1199px) {
//             bottom: -33px;
//             right: 15px;
//         }
//       }
//       .image__checkbox-select-photo-box {

//         .image__checkbox-select-photo {
//           position: absolute;
//           z-index: 2;
//           top: 10px;
//           right: 10px;
//           width: 40px;
//           height: 40px;
//           border-radius: 10px;
//           @media (max-width: $max-width-mobile) {
//             width: 35px;
//             height: 35px;
//           }
//         }
//         .image__checkbox-favourites-photo {
//           position: absolute;
//           z-index: 2;
//           top: 70px;
//           right: 10px;
//           width: 40px;
//           height: 40px;
//           border-radius: 10px;
//           @media (max-width: $max-width-mobile) {
//             width: 35px;
//             height: 35px;
//           }
//         }
//         .image__checkbox-download-photo {
//           position: absolute;
//           z-index: 2;
//           top: 70px;
//           right: 10px;
//           width: 40px;
//           height: 40px;
//           border-radius: 10px;
//           @media (max-width: $max-width-mobile) {
//             width: 35px;
//             height: 35px;
//           }
//         }
//         .custom-checkbox {
//           display: flex;
//           align-items: center;
//           gap: 5px;
  
//           // Скрываем стандартный чек-бокс
//           .custom-checkbox-input {
//             display: none;
//           }
//           // Показываем картинку - кастомного чек-бокса
//           .custom-checkbox-icon {
//             display: inline-block;
//             width: 40px;
//             height: 40px;
//             background-image: url('../../../assets/images/image-checkbox-uncheck.png');
//             background-size: cover;
//             vertical-align: middle;
//             cursor: pointer;
//             border-radius: 25px;
//             @media (max-width: $max-width-mobile) {
//                 width: 35px;
//                 height: 35px;
//               }
//           }
//           /* Меняем изображение при активации чекбокса */
//           .custom-checkbox-input:checked + .custom-checkbox-icon {
//             background-image: url('../../../assets/images/image-checkbox-checked.png');
//           }
//         }
//         /* Кастомный чекбокс - Избранное */
//         .custom-checkbox-favourites {
//           display: flex;
//           align-items: center;
//           gap: 5px;
  
//           .custom-checkbox-input-favourites {
//             display: none;
//           }
//           /* Стили для кастомной галочки */
//           .custom-checkbox-icon-favourites {
//             display: inline-block;
//             width: 40px;
//             height: 40px;
//             background-image: url('../../../assets/images/image-checkbox-uncheck-favourite.svg');
//             background-size: cover;
//             vertical-align: middle;
//             cursor: pointer;
//             border-radius: 25px;
//             @media (max-width: $max-width-mobile) {
//               width: 35px;
//               height: 35px;
//             }
//           }
//           /* Меняем изображение при активации чекбокса */
//           .custom-checkbox-input-favourites:checked + .custom-checkbox-icon-favourites {
//             background-image: url('../../../assets/images/image-checkbox-favourites-v2.svg');
//           }
//         }
//         // Кастомный чекбокс - Скачать
//         .custom-checkbox-download {
//           display: flex;
//           align-items: center;
//           gap: 5px;
  
//           .custom-checkbox-input-download {
//             display: none;
//           }
//           /* Стили для кастомной галочки */
//           .custom-checkbox-icon-download {
//             display: inline-block;
//             width: 40px;
//             height: 40px;
//             background-image: url('../../../assets/images/image-checkbox-uncheck.png');
//             background-size: cover;
//             vertical-align: middle;
//             cursor: pointer;
//             border-radius: 25px;
//             @media (max-width: $max-width-mobile) {
//               width: 35px;
//               height: 35px;
//             }
//           }
//           /* Меняем изображение при активации чекбокса */
//           .custom-checkbox-input-download:checked + .custom-checkbox-icon-download {
//             background-image: url('../../../assets/images/image-checkbox-download.svg');
//           }
//         }
//       }
//       @media (max-width: 1199px) {
//         max-width: 100vw;
//       }
//       @media (max-width: 767px) {
//         max-height: 69%;
//         max-width: 100vw;
//       }
//     }
//     .photo-carousel__info-box {
//       display: flex;
//       flex-shrink: 0;
//       max-width: 740px;
//       padding: 1px;
//       margin-top: 20px;
//       margin-bottom: 30px;
//       p {
//         font-size: 16px;
//         @media (max-width: 767px) {
//             font-size: 14px;
//         }
//       }

//       @media (max-width: 1199px) {
//         display: none;
//         padding-top: 0px;
//       }
//       @media (max-width: 767px) {
//         margin-left: 15px;
//         margin-right: 15px;
//       } 
//     }
//     .photo-carousel__for-balance {
//       display: none;
//       height: 20px;
//       @media (max-width: 1199px) {
//         display: flex;
//         height: 50px;
//       }
//     }
//     @media (max-width: $max-width-tablet) {
//       justify-content: space-between;
//       .photo-carousel__title-box {
//         order: 1;
//       }
//       .photo-carousel__photo-box {
//         order: 3;
//       }
//       .photo-carousel__info-box {
//         order: 2;
//       }
//       .photo-carousel__for-balance {
//         order: 4;
//       }
//     }
//   }
//   .photo-carousel__footer {
//       min-height: 80px;
//       height: 80px;
//   }
// }