@import "../../App/var";
@import "../../App/App.scss";

/* Чек-бокс квадратный */
.image__checkbox-square-select-photo-box {
  width: 24px;
  height: 24px;
  position: relative;
}
.image__checkbox-square-select-photo {
  position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    border-radius: 0px;
}
.custom-checkbox-square {
  display: flex;
  align-items: center;
  gap: 5px;
}
/* Скрываем стандартный чек-бокс */
.custom-checkbox-square-input {
  display: none;
}
/* Показываем картинку - кастомного чек-бокса */
.custom-checkbox-square-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('../../../assets/images/image-checkbox-square-uncheck.png');
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 0px;
}
/* Меняем изображение при активации чекбокса */
.custom-checkbox-square-input:checked + .custom-checkbox-square-icon {
  background-image: url('../../../assets/images/image-checkbox-square-checked.png');
}

.hr-gray {
  width: 100%;
  padding: 0;
  margin: 0;
  border-style: none;
  height: 1px;
  background-color: rgb(69, 86, 92);;
}

.company-reg-step-one {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .company-reg-step-one__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 50px;

    .title-description {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-family: "MV-WEEKEND", sans-serif;
        font-size: 64px;
        line-height: 64px;
        color: rgb(238, 251, 255);
        text-align: center;
        margin-bottom: 10px;

        @media (max-width: $max-width-mobile) {
          font-family: "Golos-UI", sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          color: rgb(69, 86, 92);
          text-transform: uppercase;
        }
      }
      p {
        text-align: center;
        margin-bottom: 10px;
      }
      @media (max-width: $max-width-mobile) {
        margin-top: 50px;
      }
      @media (max-width: $max-width-tablet) {
        margin-top: 50px;
      }
    }
    .company-reg__form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 600px;
      width: 100%;

      .input-fio__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;

        .input__box-three {
          display: flex;
          flex-direction: column;
          width: 100%;
          min-width: 190px;
          max-width: 31%;
        }
      }

      .text-box-bold {

        p {
          font-weight: 500;
        }
      }

      .element-box {
        display: flex;
        align-items: start;
        gap: 10px;

        .text-wrapper {
          padding-top: 5px;

          .text-color {
            color: $color-dark-blue-gray07;
          }
          .link {
            color: $color-cool-stone;
            text-decoration: underline;
          }
          .link:hover {
            cursor: pointer;
            color: $color-misty-sea;
          }
        }
      }

      .bts-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
}