@import '../../../App/var';
@import '../../../App/App.scss';

.greetings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .greetings__title {
    margin-bottom: 0px;
    margin-left: 30px;
    margin-right: 30px;

    h1 {
      @include mv-weekend;
      font-size: 100px;
      line-height: 80px;
      color: $color-light-blue;
      text-align: center;
      margin-bottom: 50px;

      @media (max-width: $max-width-tablet) {
        margin-bottom: 50px;
      }
      @media (max-width: $max-width-mobile) {
        font-size: 45px;
        line-height: 36px;
        margin-bottom: 20px;
      }
    }
  }
  .greetings__image-box {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    img {
      max-height: 370px;
      height: 100%;
      width: auto;
      position: relative;
      z-index: 1;
      mix-blend-mode: multiply;
      opacity: 0.8;
      margin-bottom: 0px;
    }
    @media (max-width: $max-width-tablet) {
      max-height: 30vh;
    }
    @media (max-width: $max-width-mobile) {
      max-height: 28vh;
    }
  }
  .greetings__text-box {
    max-width: 662px;

    p {
      font-size: $fz-l;
      text-align: center;

      @media (max-width: $max-width-mobile) {
        font-size: 16px;
      }
    }
  }

  @media (max-width: $max-width-tablet) {
    justify-content: center;

    .greetings__title {
      order: 2;
    }
    .greetings__image-box {
      order: 1;
    }
    .greetings__text-box {
      order: 3;
    }
  }
}