@import "../../App/var";
@import "../../App/App.scss";

// Фон для всех Алертов
.techworks,
.connection,
.wait {
  display: flex;
  min-height: 100vh;
  background: url("../../../assets/images/image-noize.png"), 
    linear-gradient(90deg, 
      rgba(151, 199, 204, 1) 0%, 
      rgba(192, 232, 237, 1) 40%, 
      rgba(192, 232, 237, 1) 60%, 
      rgba(151, 199, 204, 1) 100%);
  background-size: 50px 50px, auto;
  background-repeat: round, no-repeat;
}
.techworks,
.connection,
.wait {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .techworks__image-box,
  .connection__image-box,
  .wait__image-box {
    display: flex;
    justify-content: center;
    max-width: 200px;
    height: auto;
    margin-bottom: 50px;

    img {
      object-fit: contain;     
      position: relative;
      z-index: 1;
      mix-blend-mode: multiply;
      opacity: 0.6;
      margin-bottom: 0px;
    }
    @media (max-width: $max-width-tablet) {
      max-height: 30vh;
    }
    @media (max-width: $max-width-mobile) {
      max-height: 28vh;
      max-width: 170px;
    }
  }
  .techworks__title-box,
  .connection__title-box,
  .wait__title-box {

    h2 {
      @include mv-weekend;
      font-size: $fz-xxxl2;
      color: $color-light-blue;
      text-align: center;

      @media (max-width: $max-width-mobile) {
        font-size: $fz-xxl2;
      }
    }
  }
  .connection__btn-box {
    margin-top: 50px;

    p {
      text-transform: uppercase;
    }
  }
}