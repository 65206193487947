@charset "UTF-8";
.bottom-bar {
  display: none;
}
@media (max-width: 1199px) {
  .bottom-bar {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    gap: 10px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 100%;
    height: 152px;
    background: url("../../../assets/images/v9-center-200.png") no-repeat center 6px/200px 152px, url("../../../assets/images/v9-footer-down-all-width-after-photoshop.png") no-repeat center bottom/100% 97px, transparent;
    padding: 25px 20px;
  }
  .bottom-bar .bottom-bar__left, .bottom-bar .bottom-bar__right {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    gap: 5px;
  }
  .bottom-bar .bottom-bar__left .bottom-bar__image-box, .bottom-bar .bottom-bar__right .bottom-bar__image-box {
    display: flex;
    justify-content: center;
    height: 50px;
    width: 70px;
  }
  .bottom-bar .bottom-bar__left .bottom-bar__image-box img, .bottom-bar .bottom-bar__right .bottom-bar__image-box img {
    height: 100%;
    width: auto;
  }
}
@media (max-width: 1199px) and (max-width: 767px) {
  .bottom-bar .bottom-bar__left .bottom-bar__image-box, .bottom-bar .bottom-bar__right .bottom-bar__image-box {
    height: 30px;
    width: 40px;
  }
}
@media (max-width: 1199px) {
  .bottom-bar .bottom-bar__left .invisible-box, .bottom-bar .bottom-bar__right .invisible-box {
    height: 50px;
    width: 70px;
  }
}
@media (max-width: 1199px) and (max-width: 767px) {
  .bottom-bar .bottom-bar__left .invisible-box, .bottom-bar .bottom-bar__right .invisible-box {
    height: 30px;
    width: 40px;
  }
}
@media (max-width: 1199px) {
  .bottom-bar .bottom-bar__centr {
    display: flex;
    justify-content: center;
    flex-grow: 0.5;
    width: 70px;
    height: 110px;
    position: relative;
  }
  .bottom-bar .bottom-bar__centr img {
    width: 66px;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10%;
    z-index: 1;
    mix-blend-mode: multiply;
    opacity: 0.95;
  }
}
@media (max-width: 1199px) and (max-width: 767px) {
  .bottom-bar .bottom-bar__centr img {
    width: 45px;
  }
}
@media (max-width: 767px) {
  .bottom-bar {
    height: 113px; /* Новая высота контейнера (было 150px) */
    background: url("/src/assets/images/bg-bottom-bar-mobile-center-150-130px.png") no-repeat center bottom/150px 97px, url("/src/assets/images/bg-bottom-bar-mobile-390-90px.png") no-repeat center bottom/100% 68px, transparent;
    padding: 20px 15px; /* Уменьшаем отступы пропорционально (опционально) */
  }
}/*# sourceMappingURL=BottomBar.css.map */