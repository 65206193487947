@import '../../Components/App/var';
@import '../../Components/App/App.scss';

// Стили для Видео будут применяться только внутри класса ring-wrapper
.ring-wrapper {
    // Стили для класса ring-wrapper описаны в файле FindSelf.scss

    video {
        // position: absolute;
        // z-index: 100;
        // top: 0px;
        // left: -75px;
        // width: 600px !important;
        // height: 450px !important;
        // @media (max-width: $max-width-tablet) {
        //     width: 467px !important;
        //     height: 350px !important;
        //     left: -58px;
        // }
        // @media (max-width: $max-width-mobile) {
        //     width: 347px !important;
        //     height: 260px !important;
        //     left: -43px;
        // }

        position: absolute;
        z-index: 15;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scaleX(-1) !important; /* Центрирование + зеркальное отражение */
        width: 100%; /* Занимает всю ширину родителя */
        height: 100%; /* Занимает всю высоту родителя */
        object-fit: cover; /* Обрезает лишнее */
        
        /* Убираем медиа-запросы — они теперь не нужны */
    }
}

canvas {
    // position: absolute;
    // z-index: 101;
    // top: 0px;
    // left: -75px;
    // width: 600px !important;
    // height: 450px !important;
    // transform: scaleX(-1);
    // @media (max-width: $max-width-tablet) {
    //     width: 467px !important;
    //     height: 350px !important;
    //     left: -58px;
    // }
    // @media (max-width: $max-width-mobile) {
    //     width: 347px !important;
    //     height: 260px !important;
    //     left: -43px;
    // }

    position: absolute;
    z-index: 16;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    /* Убираем медиа-запросы — они теперь не нужны */
}