@import '../../App/App.scss';

.confirm-exit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 30px;

    @media (max-width: $max-width-mobile) {
      font-size: $fz-xs;
    }
  }

  .confirm-exit__buttons-box {
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {

      p {
        margin: 0;
      }
    }
  }
}