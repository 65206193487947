@import "../../../../App/var";
@import "../../../../App/App.scss";

.cart-photos {
  // width: 67%;
  // max-height: 60vh;
  // display: flex;
  // flex-direction: column;
  // overflow: hidden;
  // overflow-y: auto;
  // flex-shrink: 10;
  // padding-right: 10px;

  // @media (max-width: $max-width-tablet) {
  //   width: 100%;
  //   max-height: 65vh;
  //   padding-right: 0;
  // }

  width: 67%;
  max-height: 745px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  flex-shrink: 10;
  padding-right: 10px;

  @media (max-width: $max-width-tablet) {
    width: 100%;
    max-height: 65vh;
    padding-right: 0;
  }
}